import { GET_LIACCOUNT, UPDATE_LIACCOUNT, LIACCOUNT_ERROR, LIACCOUNT_SUCCESS, LIACCOUNT_HIDE_MESSAGE, LIACCOUNT_VERIFY, LIACCOUNT_RESEND_CODE, AUTOMATION_MENU_SETCURRENT, PROFILE_MENU_SETCURRENT, SETTINGS_MENU_SETCURRENT, LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS, LIACCOUNT_SEND_INVITE, LIACCOUNT_LIST, UPDATE_LIACCOUNT_PASSWORD } from "../constants/LiAccount";

export function liAccountGet(id) {
    return {
        type: GET_LIACCOUNT,
        id
    };
}

export function liAccountSuccess(data) {
    return {
        type: LIACCOUNT_SUCCESS,
        data
    }
}
export function liAccountError(data) {
    return {
        type: LIACCOUNT_ERROR,
        data
    }
}
export function liAccountUpdate(id, data) {
    return {
        type: UPDATE_LIACCOUNT,
        id,
        data
    }
}
export function liAccountUpdatePassword(id, data) {
    return {
        type: UPDATE_LIACCOUNT_PASSWORD,
        id,
        data
    }
}

export function liAccountHideMessage() {
    return {
        type: LIACCOUNT_HIDE_MESSAGE,
    }
}
export function liAccountVerify(id, code) {
    return {
        type: LIACCOUNT_VERIFY,
        id,
        code
    }
}
export function liAccountResendCode(id) {
    return {
        type: LIACCOUNT_RESEND_CODE,
        id
    }
}
export function automationMenuSetCurrent(id) {
    return {
        type: AUTOMATION_MENU_SETCURRENT,
        id
    }
}
export function profileMenuSetCurrent(id) {
    return {
        type: PROFILE_MENU_SETCURRENT,
        id
    }
}
export function settingsMenuSetCurrent(id) {
    return {
        type: SETTINGS_MENU_SETCURRENT,
        id
    }
}
export function liAccountSendInvite(id) {
    return {
        type: LIACCOUNT_SEND_INVITE,
        id
    }
}
export function liAccountList(data) {
    return {
        type: LIACCOUNT_LIST,
        data
    }
}