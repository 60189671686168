
import {
    CONTACT_LIST, CONTACT_GET, CONTACT_ERROR, CONTACT_RESET, CONTACT_DELETE, CONTACT_SEND_LINKEDIN_CHAT, CONTACT_DELETE_TAG, CONTACT_ADD_TAG,
    CONTACT_HIDE_MESSAGE, CONTACT_SUCCESS, CONTACT_UPDATE, CONTACT_GET_LINKEDIN_CHAT, CONTACT_SET_QUERY, CONN_STATUS_CONNECTED, CONTACT_INIT, CONTACT_DELETE_NOTE, CONTACT_ADD_NOTE, CONTACT_CSV_DOWNLOAD
} from "redux/constants/Contacts";
import { merge } from "lodash";

const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    contacts: [],
    currentContact: {},
    refreshId: 0,
    pagination: {
        current: 1,
        pageSize: 10,
        total: 10
    },
    query: {
        sortBy: "createdAt",
        sortType: "descend",
        accountId: 0,
        contactStatus: "connection",
        status: []
    },
    currentAction: ""
}

export const Contact = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case CONTACT_RESET:
            return JSON.parse(JSON.stringify(initialState));
        case CONTACT_INIT:
            return merge(JSON.parse(JSON.stringify(initialState)), action.data)
        case CONTACT_LIST:
            state.query = { ...state.query, ...action.data };
            return {
                ...state,
                loading: true, isShowMessage: false, contacts: []
            }
        case CONTACT_GET:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CONTACT_ADD_NOTE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CONTACT_DELETE_NOTE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CONTACT_ADD_TAG:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CONTACT_DELETE_TAG:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CONTACT_GET_LINKEDIN_CHAT:
            state.currentAction = CONTACT_GET_LINKEDIN_CHAT;
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CONTACT_SEND_LINKEDIN_CHAT:
            state.currentAction = CONTACT_SEND_LINKEDIN_CHAT;
            state.currentChat.push(action.data)
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CONTACT_UPDATE:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case CONTACT_SET_QUERY:
            state.refreshId = Math.floor(Math.random() * 1000);
            return {
                ...state,
                query: { ...state.query, ...action.data },
            }

        case CONTACT_DELETE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }

        case CONTACT_SUCCESS:
            if (apiCall === CONTACT_LIST) {
                state.contacts = result;
                state.pagination.current = result.page;
                state.pagination.total = result.totalResults;
                state.pagination.pageSize = result.limit;
            }
            else if (apiCall === CONTACT_GET)
                state.currentContact = result;
            else if (apiCall === CONTACT_SEND_LINKEDIN_CHAT) {
                state.currentChat[state.currentChat.length - 1].status = "sent";
            }
            else if (apiCall === CONTACT_GET_LINKEDIN_CHAT)
                state.currentChat = result;
            else if (apiCall === CONTACT_ADD_NOTE) {
                state.currentContact.contact.notes.push(result);

            }
            else if (apiCall === CONTACT_DELETE_NOTE) {
                state.currentContact.contact.notes = state.currentContact.contact.notes.filter(item => item._id != result.id);
            }
            else if (apiCall === CONTACT_ADD_TAG)
                state.currentContact.tags.push(result);
            else if (apiCall === CONTACT_DELETE_TAG)
                state.currentContact.tags = state.currentContact.tags.filter(item => item != result);
            else if (apiCall === CONTACT_CSV_DOWNLOAD)
                state.csvDownloadData = result;
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case CONTACT_ERROR:
            if (apiCall === CONTACT_SEND_LINKEDIN_CHAT) {
                state.currentChat[state.currentChat.length - 1].status = "error";
            }
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case CONTACT_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }
        case CONTACT_CSV_DOWNLOAD:
            return {
                ...state,
                loading: true, isShowMessage: false, success: undefined
            }
        default:
            return state;
    }
}

export default Contact;