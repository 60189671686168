
import {
    GET_SYSTEM_OPTIONS, GET_ACCOUNT_OPTIONS, GET_SYSTEM_OPTIONS_ERROR, GET_SYSTEM_OPTIONS_SUCCESS, GET_SYSTEM_CAMPAIGN_OPTIONS, GET_SYSTEM_CONTACT_OPTIONS
} from "redux/constants/System";
const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
}
const System = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case GET_SYSTEM_CAMPAIGN_OPTIONS:
            return {
                ...state,
                loading: true
            }
        case GET_SYSTEM_CONTACT_OPTIONS:
            return {
                ...state,
                loading: true
            }
        case GET_SYSTEM_OPTIONS:
            return {
                ...state,
                loading: true
            }
        case GET_ACCOUNT_OPTIONS:
            return {
                ...state,
                loading: true
            }
        case GET_SYSTEM_OPTIONS_SUCCESS:
            if (apiCall === GET_SYSTEM_CAMPAIGN_OPTIONS) {
                state.campaignOptions = result;
            }
            else if (apiCall === GET_SYSTEM_CONTACT_OPTIONS) {
                state.contactOptions = result;
            }
            else if (apiCall === GET_ACCOUNT_OPTIONS) {

                state.accountOptions = result;
            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }

        case GET_SYSTEM_OPTIONS_ERROR:
            return {

                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }

        default:

            return state;
    }
}

export default System;