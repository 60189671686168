import { NEW_CRAWLER_ADD, NEW_CRAWLER_ERROR, NEW_CRAWLER_INIT, NEW_CRAWLER_RESET, NEW_CRAWLER_SET_SOURCE, NEW_CRAWLER_SET_STEP, NEW_CRAWLER_SET_WIZARD_STATE, NEW_CRAWLER_SOURCE_TEST, NEW_CRAWLER_SUCCESS } from "redux/constants/Crawler";
import { merge } from "lodash";
import { SOURCE_TYPE_QUICK_SEARCH_SALES } from "constants/MainConstant";
const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    currentStep: 0,
    rand: 0,
    wizardState: {
        crawlerStart: "immediate",
        notifications: false,
        autoDelivery: false,
        deliverOnFinish: ["email"],
        notifyOnFinish: ["email"],
        notifyOnError: ["email"],
        notifyOnFinishEmails: [],
        notifyOnErrorEmails: [],
        autoDeliveryEmails: [],
        deliveryFormat: "csv",
        name: "",
        systemFilters: [],
        nameError: false,
        added: false,
        searchUrl: "",
        maximumAmountResult: 0
    },
}

export const NewCrawler = (state = JSON.parse(JSON.stringify(initialState)), action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }

    switch (action.type) {
        case NEW_CRAWLER_RESET:
            return JSON.parse(JSON.stringify(initialState));
        case NEW_CRAWLER_INIT:
            return merge(JSON.parse(JSON.stringify(initialState)), action.data);
        case NEW_CRAWLER_SET_STEP:
            return {
                ...state,
                currentStep: action.data
            }
        case NEW_CRAWLER_SET_WIZARD_STATE:
            const wizardState = { ...state.wizardState, ...action.data };
            return {
                ...state,
                rand: Math.floor(Math.random() * 1000),
                wizardState
            };
        case NEW_CRAWLER_SET_SOURCE:
            state.wizardState.maximumAmountResult = 1000;
            if (action.data.selectedSource == SOURCE_TYPE_QUICK_SEARCH_SALES)
                state.wizardState.maximumAmountResult = 2500;
            return {
                ...state,
                currentStep: action.data.currentStep,
                wizardState: { ...state.wizardState, ...action.data }
            };
        case NEW_CRAWLER_SOURCE_TEST:
            state.wizardState.searchUrl = action.data.url;
            return {
                ...state,
                loading: true
            }
        case NEW_CRAWLER_SUCCESS:
            if (apiCall === NEW_CRAWLER_SOURCE_TEST) {
                state.wizardState.testResult = result;
                state.wizardState.apiUrl = result.apiUrl;
                state.wizardState.maximumAmountResult = result.totalCount;
            }
            else if (apiCall === NEW_CRAWLER_ADD) {
                state.wizardState.added = true;
                state.rand = Math.floor(Math.random() * 1000)
            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case NEW_CRAWLER_ERROR:
            if (apiCall === NEW_CRAWLER_SOURCE_TEST)
                delete state.wizardState.testResult;
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        default:
            return state;
    }
}

export default NewCrawler