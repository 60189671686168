import { subscriptionCancel } from "redux/actions";
import {
    GET_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_PLANS, UPDATE_SUBSCRIPTION, SUBSCRIBE_TO_PLAN,
    SUBSCRIPTION_ERROR, SUBSCRIPTION_SUCCESS, SUBSCRIPTION_HIDE_MESSAGE, SUBSCRIPTION_SWITCH, SUBSCRIPTION_CANCEL
} from "redux/constants/Subscription";
const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    plans: [],
}


export const Subscriptioon = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {

        case GET_SUBSCRIPTION_PLANS:
            return {
                ...state,
                loading: true
            }
        case SUBSCRIPTION_SWITCH:
            return {
                ...state,
                loading: true
            }
        case SUBSCRIPTION_CANCEL:
            return {
                ...state,
                loading: true
            }
        case SUBSCRIPTION_SUCCESS:
            if (apiCall === "plans")
                state.plans = result;
            else {
                if (result)
                    state.subscription = Object.keys(result).length === 0 ? undefined : result;
                else
                    state.subscription = result;
            }

            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case SUBSCRIPTION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case SUBSCRIPTION_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }

        default:
            return state;
    }
}

export default Subscriptioon;