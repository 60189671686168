import {
    CAMPAIGN_LIST, CAMPAIGN_GET, CAMPAIGN_ERROR, CAMPAIGN_ARCHIVE, CAMPAIGN_DELETE,
    CAMPAIGN_HIDE_MESSAGE, CAMPAIGN_SUCCESS, CAMPAIGN_SET_STATE, CAMPAIGN_UPDATE, CAMPAIGN_ADD_LEAD_SOURCE, CAMPAIGN_SOURCE_TEST, CAMPAIGN_DUPLICATE, CAMPAIGN_SAVE_AS_TEMPLATE, CAMPAIGN_INIT, CAMPAIGN_RESET, CAMPAIGN_SET_QUERY
} from "redux/constants/Campaign";

export function campaignGet(data) {
    return {
        type: CAMPAIGN_GET,
        data
    }
}
export function campaignArchive(data) {
    return {
        type: CAMPAIGN_ARCHIVE,
        data
    }
}
export function campaignDuplicate(data) {
    return {
        type: CAMPAIGN_DUPLICATE,
        data
    }
}
export function campaignSaveAsTemplate(data) {
    return {
        type: CAMPAIGN_SAVE_AS_TEMPLATE,
        data
    }
}
export function campaignSetQuery(data) {
    return {
        type: CAMPAIGN_SET_QUERY,
        data
    }
}
export function campaignList(accountId) {
    return {
        type: CAMPAIGN_LIST,
        accountId
    }
}

export function campaignSuccess(data) {
    return {
        type: CAMPAIGN_SUCCESS,
        data,
    }
}
export function campaignHideMessage() {
    return {
        type: CAMPAIGN_HIDE_MESSAGE,
    }
}
export function campaignError(data) {
    return {
        type: CAMPAIGN_ERROR,
        data

    }
}
export function campaignInit(data) {
    return {
        type: CAMPAIGN_INIT,
        data

    }
}
export function campaignReset(data) {
    return {
        type: CAMPAIGN_RESET,
        data

    }
}
export function campaignSetState(data) {
    return {
        type: CAMPAIGN_SET_STATE,
        data

    }
}
export function campaignUpdate(id, data) {
    return {
        type: CAMPAIGN_UPDATE,
        data,
        id
    }
}
export function campaignDelete(id) {
    return {
        type: CAMPAIGN_DELETE,
        id
    }
}
export function campaignAddLeadSource(id, data) {
    return {
        type: CAMPAIGN_ADD_LEAD_SOURCE,
        data,
        id
    }
}

export function campaignSourceTest(data) {
    return {
        type: CAMPAIGN_SOURCE_TEST,
        data
    }
}