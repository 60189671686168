import React from 'react';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import AppViews from 'views/app-views';
import { useSelector } from 'react-redux';
import {
  Layout,
  Grid,
} from "antd";

import navigationConfig, { navigationConfigManager, navigationConfigCLient, navigationConfigManagerWhiteLabel } from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const userData = useSelector(state => state.user.userData);
  if (userData && userData.role == "admin") {
    var navigation = navigationConfig;
  }
  else if (userData && userData.role == "client") {
    var navigation = navigationConfigCLient;
  }
  else if (userData && userData.role == "manager" && userData.whiteLabel == true) {
    var navigation = navigationConfigManagerWhiteLabel;
  }
  else {
    var navigation = navigationConfigManager;
  }
  const currentRouteInfo = utils.getRouteInfo(navigation, location.pathname)
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = screens.length === 0 ? false : !screens.includes('lg')
  const isNavSide = navType === NAV_TYPE_SIDE
  const isNavTop = navType === NAV_TYPE_TOP
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
  }

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() }
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() }
    }
    return { paddingLeft: getLayoutGutter() }
  }
  let marginTop = "65px";
  let contentMarginTop = "30px"
  if (!isMobile) {
    marginTop = "-5px";
    contentMarginTop = "30px";

  }
  return (

    <Layout>
      {userData && <>
        <HeaderNav isMobile={isMobile} />
        {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-container">
          {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo} /> : null}
          <div className='position-absolute  w-100' style={{ zIndex: "-1", left: "0px:", top: "0px", height: "70px", backgroundColor: "#ffffff" }}></div>
          <Layout className="app-layout" style={getLayoutDirectionGutter()}>
            <div style={{ marginTop: marginTop }} className={`mb-2 app-content ${isNavTop ? 'layout-top-nav' : ''}`} >
              <PageHeader display={currentRouteInfo?.breadcrumb} title={currentRouteInfo?.title} />
              <div style={{ contentMarginTop }}>
                <Content>
                  <AppViews />
                </Content>
              </div>
            </div>
            {//<Footer />
              //
            }
          </Layout>
        </Layout>
        {isMobile && <MobileNav />}
      </>}
    </Layout>
  )
}

const mapStateToProps = ({ theme, user }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale, user }
};

export default connect(mapStateToProps)(React.memo(AppLayout));