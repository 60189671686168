import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import {
    GET_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_PLANS, UPDATE_SUBSCRIPTION, SUBSCRIBE_TO_PLAN,
    SUBSCRIPTION_ERROR, SUBSCRIPTION_SUCCESS, SUBSCRIPTION_CANCEL, SUBSCRIPTION_SWITCH
} from "redux/constants/Subscription";
import { subscriptionPlans, subscriptionPlan, subscriptionError, subscriptionSuccess } from '../actions/Subscription';
import Service from 'services/SubscriptionService';
export function* plans() {
    yield takeLatest(GET_SUBSCRIPTION_PLANS, function* () {
        try {
            const result = yield call(Service.plans);
            yield put(subscriptionSuccess({ result, success: true, isShowMessage: false, apiCall: 'plans' }));
        } catch (err) {
            yield put(subscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export function* cancel() {
    yield takeLatest(SUBSCRIPTION_CANCEL, function* (params) {
        try {
            const result = yield call(Service.cancel, params.inAccountId);
            yield put(subscriptionSuccess({
                result, success: true, isShowMessage: true,
                message: "Subscription cancelled.", apiCall: 'subscription'
            }));
        } catch (err) {
            yield put(subscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export function* switchPlan() {
    yield takeLatest(SUBSCRIPTION_SWITCH, function* (params) {
        try {
            const result = yield call(Service.switchPlan, params.inAccountId, params.newPlanId);
            yield put(subscriptionSuccess({
                result, success: true, isShowMessage: true,
                message: "Subscription updated.", apiCall: 'subscription'
            }));
        } catch (err) {
            yield put(subscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export default function* rootSaga() {
    yield all([
        fork(plans),
        fork(cancel),
        fork(switchPlan),
    ]);
}