import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { CAMPAIGN_LIST, CAMPAIGN_GET, CAMPAIGN_SUCCESS, CAMPAIGN_HIDE_MESSAGE, CAMPAIGN_ERROR, CAMPAIGN_SET_STATE, CAMPAIGN_UPDATE, CAMPAIGN_ADD_LEAD_SOURCE, CAMPAIGN_SOURCE_TEST, CAMPAIGN_DUPLICATE, CAMPAIGN_ARCHIVE, CAMPAIGN_SAVE_AS_TEMPLATE, CAMPAIGN_DELETE } from "redux/constants/Campaign";

import Service from 'services/CampaignService';
import { campaignSuccess, campaignError } from 'redux/actions/Campaign';
export function* campaignList() {
    yield takeLatest(CAMPAIGN_LIST, function* (params) {
        try {
            const result = yield call(Service.campaignList, params.accountId);
            yield put(campaignSuccess({ result, success: true, isShowMessage: false, apiCall: CAMPAIGN_LIST }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_LIST }))
        }
    })

}


export function* campaignUpdate() {
    yield takeLatest(CAMPAIGN_UPDATE, function* (params) {
        try {
            const result = yield call(Service.campaignUpdate, params.id, params.data);
            result.id = params.id;
            yield put(campaignSuccess({ result, success: true, isShowMessage: false, apiCall: CAMPAIGN_UPDATE }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_UPDATE }))
        }
    })

}
export function* campaignDelete() {
    yield takeLatest(CAMPAIGN_DELETE, function* (params) {
        try {
            const result = yield call(Service.campaignDelete, params.id);
            yield put(campaignSuccess({ result: params.id, success: true, isShowMessage: false, apiCall: CAMPAIGN_DELETE }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_DELETE }))
        }
    })

}
export function* campaignDuplicate() {
    yield takeLatest(CAMPAIGN_DUPLICATE, function* (params) {
        try {
            params.data.updateType = CAMPAIGN_DUPLICATE;
            const result = yield call(Service.campaignAdvanceUpdate, params.id, params.data);
            yield put(campaignSuccess({ result, success: true, isShowMessage: false, apiCall: CAMPAIGN_DUPLICATE }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_DUPLICATE }))
        }
    })

}
export function* campaignArchive() {
    yield takeLatest(CAMPAIGN_ARCHIVE, function* (params) {
        try {
            params.data.updateType = CAMPAIGN_ARCHIVE;

            const result = yield call(Service.campaignAdvanceUpdate, params.id, params.data);
            yield put(campaignSuccess({ result, success: true, isShowMessage: false, apiCall: CAMPAIGN_ARCHIVE }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_ARCHIVE }))
        }
    })

}
export function* campaignSaveAsTemplate() {
    yield takeLatest(CAMPAIGN_SAVE_AS_TEMPLATE, function* (params) {
        try {
            const result = yield call(Service.campaignSaveAsTemplate, params.data);
            yield put(campaignSuccess({ result, success: true, isShowMessage: true, message: "Template created", apiCall: CAMPAIGN_SAVE_AS_TEMPLATE }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_SAVE_AS_TEMPLATE }))
        }
    })

}

export function* addLeadsSource() {
    yield takeLatest(CAMPAIGN_ADD_LEAD_SOURCE, function* (params) {
        try {
            const result = yield call(Service.addLeadsSource, params.id, params.data);
            yield put(campaignSuccess({ result, success: true, isShowMessage: false, apiCall: CAMPAIGN_ADD_LEAD_SOURCE }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_ADD_LEAD_SOURCE }))
        }
    })

}
export function* campaginGet() {
    yield takeLatest(CAMPAIGN_GET, function* (params) {
        try {

            const result = yield call(Service.campaignGet, params.data);
            yield put(campaignSuccess({ result, success: true, isShowMessage: false, apiCall: CAMPAIGN_GET }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_GET }))
        }
    })

}

export function* testSource() {
    yield takeLatest(CAMPAIGN_SOURCE_TEST, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.testSearch, params.data);
            yield put(campaignSuccess({ result, success: true, isShowMessage: false, apiCall: CAMPAIGN_SOURCE_TEST }));
        } catch (err) {
            yield put(campaignError({ isShowMessage: true, success: false, message: err.message, apiCall: CAMPAIGN_SOURCE_TEST }))
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(campaignList),
        fork(campaginGet),
        fork(campaignUpdate),
        fork(addLeadsSource),
        fork(testSource),
        fork(campaignArchive),
        fork(campaignDuplicate),
        fork(campaignSaveAsTemplate),
        fork(campaignDelete),

    ]);
}