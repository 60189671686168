import React from "react";
import { CheckOutlined, MenuOutlined, DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from "antd";
import lang from "assets/data/language.data.json";
import { connect, useSelector } from "react-redux";
import { onLocaleChange } from 'redux/actions/Theme'
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import { Link } from "react-router-dom";

import navigationConfig, { navigationConfigCLient, navigationConfigManager, navigationConfigManagerWhiteLabel } from "configs/NavigationConfig";
const { SubMenu } = Menu;

const setLocale = (isLocaleOn, localeKey) =>
	isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

export const NavLanguage = ({ locale, configDisplay, onLocaleChange }) => {
	const userData = useSelector(state => state.user.userData);
	if (userData && userData.role == "admin") {
		var navigation = navigationConfig;
	}
	else if (userData && userData.role == "client") {
		var navigation = navigationConfigCLient;
	}
	else if (userData && userData.role == "manager" && userData.whiteLabel == true) {
		var navigation = navigationConfigManagerWhiteLabel;
	}
	else {
		var navigation = navigationConfigManager;
	}
	const closeMobileNav = () => {

	}
	const languageOption = (
		<Menu>
			{navigation.map((menu) =>
				menu.submenu.length > 0 ? (
					<Menu.ItemGroup
						key={menu.key}
						title={setLocale(locale, menu.title)}
					>
						{menu.submenu.map((subMenuFirst) =>
							subMenuFirst.submenu.length > 0 ? (
								<SubMenu
									icon={
										subMenuFirst.icon ? (
											<Icon type={subMenuFirst?.icon} />
										) : null
									}
									key={subMenuFirst.key}
									title={setLocale(locale, subMenuFirst.title)}
								>
									{subMenuFirst.submenu.map((subMenuSecond) => (
										<Menu.Item key={subMenuSecond.key}>
											{subMenuSecond.icon ? (
												<Icon type={subMenuSecond?.icon} />
											) : null}
											<span>
												{setLocale(locale, subMenuSecond.title)}
											</span>
											<Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
										</Menu.Item>
									))}
								</SubMenu>
							) : (
								<Menu.Item key={subMenuFirst.key}>
									{subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
									<span>{setLocale(locale, subMenuFirst.title)}</span>
									<Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
								</Menu.Item>
							)
						)}
					</Menu.ItemGroup>
				) : (
					<Menu.Item key={menu.key}>
						{menu.icon ? <Icon type={menu?.icon} className="mr-2" /> : null}
						<span>{setLocale(locale, menu?.title)}</span>
						{menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
					</Menu.Item>
				)
			)}
		</Menu>
	)


	return (
		<Dropdown placement="bottomRight" overlay={languageOption} trigger={["click"]}>
			{

				<Menu mode="horizontal">
					<Menu.Item key="language">
						<a href="#/" onClick={e => e.preventDefault()}>
							<MenuOutlined className="nav-icon mr-0" />
						</a>
					</Menu.Item>
				</Menu>

			}
		</Dropdown>
	)
}

const mapStateToProps = ({ theme }) => {
	const { locale } = theme;
	return { locale }
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
