import {
    LINKEDIN_CAMPAIGN_SET_STEP, LINKEDIN_CAMPAIGN_SET_WIZARD_STATE,
    LINKEDIN_CAMPAIGN_SOURCE_TEST, LINKEDIN_CAMPAIGN_ADD, LINKEDIN_CAMPAIGN_SET_SEQUENCE_DATA, LINKEDIN_CAMPAIGN_HIDE_MESSAGE, LINKEDIN_CAMPAIGN_SET_WIZARD, LINKEDIN_CAMPAIGN_SET_SOURCE, LINKEDIN_CAMPAIGN_SUCCESS, LINKEDIN_CAMPAIGN_ERROR, LINKEDIN_CAMPAIGN_SET_SEQUENCE, LINKEDIN_CAMPAIGN_REMOVE_SEQUENCE, LINKEDIN_CAMPAIGN_INIT, LINKEDIN_CAMPAIGN_RESET, LINKEDIN_CAMPAIGN_REMOVE_ADDED_SOURCE, LINKEDIN_CAMPAIGN_UPDATE_DRAFT
} from "redux/constants/LinkedinCampaign";

export function linkedinCampaignSetStep(data) {
    return {
        type: LINKEDIN_CAMPAIGN_SET_STEP,
        data
    }
}
export function linkedinCampaignSetWizardState(data) {
    return {
        type: LINKEDIN_CAMPAIGN_SET_WIZARD_STATE,
        data
    }
}
export function linkedinCampaignRemoveAddedSource(data) {
    return {
        type: LINKEDIN_CAMPAIGN_REMOVE_ADDED_SOURCE,
        data
    }
}
export function linkedinCampaignInit(data) {
    return {
        type: LINKEDIN_CAMPAIGN_INIT,
        data
    }
}
export function linkedinCampaignReset(data) {
    return {
        type: LINKEDIN_CAMPAIGN_RESET,
        data
    }
}
export function linkedinCampaignSetSource(data) {
    return {
        type: LINKEDIN_CAMPAIGN_SET_SOURCE,
        data
    }
}
export function linkedinCampaignSetWizard(data, property) {
    return {
        type: LINKEDIN_CAMPAIGN_SET_WIZARD,
        data,
        property
    }
}
export function linkedinCampaignSourceTest(data) {
    return {
        type: LINKEDIN_CAMPAIGN_SOURCE_TEST,
        data
    }
}
export function linkedinCampaignHideMessage() {
    return {
        type: LINKEDIN_CAMPAIGN_HIDE_MESSAGE,
    }
}
export function linkedinCampaignAdd(data) {
    return {
        type: LINKEDIN_CAMPAIGN_ADD,
        data

    }
}
export function linkedinCampaignSuccess(data) {
    return {
        type: LINKEDIN_CAMPAIGN_SUCCESS,
        data,
    }
}
export function linkedinCampaignError(data) {
    return {
        type: LINKEDIN_CAMPAIGN_ERROR,
        data,
    }
}
export function linkedinCampaignSetSequence(data) {
    return {
        type: LINKEDIN_CAMPAIGN_SET_SEQUENCE,
        data,
    }
}
export function linkedinCampaignRemoveSequence(data) {
    return {
        type: LINKEDIN_CAMPAIGN_REMOVE_SEQUENCE,
        data,
    }
}
export function linkedinCampaignSetSequenceData(index, data) {
    return {
        type: LINKEDIN_CAMPAIGN_SET_SEQUENCE_DATA,
        index,
        data,
    }
}


export function linkedinCampaignUpdateDraft(data) {
    return {
        type: LINKEDIN_CAMPAIGN_UPDATE_DRAFT,
        data

    }
}