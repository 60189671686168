import fetch from 'auth/FetchInterceptor'
import { SOURCE_TYPE_1ST_DEGREE, SOURCE_TYPE_CSV_UPLOAD } from 'constants/MainConstant';
const CrawlerService = {};


CrawlerService.testSearch = function (data) {
    data.advance = false;
    if (data.type == SOURCE_TYPE_1ST_DEGREE)
        return fetch({
            url: '/accounts/connections',
            method: 'post',
            headers: {
                'public-request': "true"
            },
            data
        })
    else
        return fetch({
            url: '/crawler/test',
            method: 'post',
            headers: {
                'public-request': "true"
            },
            data
        })
}
CrawlerService.quickSearchAdd = function (data) {

    return fetch({
        url: '/crawler/quickSearchAdd',
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data: cleanQuickSearchData(data)
    })
}
CrawlerService.add = function (data) {
    if (data.selectedSource == SOURCE_TYPE_CSV_UPLOAD) {
        let temp = cleanlinkedinCampaignCSVData(data);
        let formData = new FormData()
        temp.searchUrl = "csv upload : " + data.csvFile.name;
        temp.systemFilters = [];
        temp.columns = temp.columns.map(item => item.dataIndex);
        Object.keys(temp).forEach(key => {
            if (key != "csvFile") {
                if (['sequenceData', 'deliveryOnFinish', 'notifyOnError', 'notifyOnErrorEmails',
                    'notifyOnFinish', 'notifyOnFinishEmails', 'systemFilters', 'columns', 'csvResult'].indexOf(key) !== -1) {
                    formData.append(key, JSON.stringify(temp[key]));
                }
                else
                    formData.append(key, temp[key]);
            }
            else {
                formData.append(key, data.csvFile, data.csvFile.name);
            }

        });

        return fetch({
            url: `/crawler/uploadCSV`,
            method: 'post',
            headers: {
                'public-request': "true",
                'content-type': 'multipart/form-data',
            },
            data: formData
        })
    }
    else
        return fetch({
            url: '/crawler',
            method: 'post',
            headers: {
                'public-request': "true"
            },
            data: cleanQuickSearchData(data)
        })
}
CrawlerService.crawlerList = function (options) {

    var queryString = bodyToQueryString(options);
    return fetch({
        url: `/crawler?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })

}

CrawlerService.contactCSVDownload = function (id, options) {
    let queryString = bodyToQueryString(options);

    return fetch({
        url: `/crawler/${id}/csvDownload?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })

}

CrawlerService.crawlerGet = function (data) {

    return fetch({
        url: '/crawler/' + data.id,
        method: 'get',
        headers: {
            'public-request': "true"
        },
        data
    })
}
CrawlerService.getStatus = function (id, accountId) {

    return fetch({
        url: `/crawler/${id}/status?accountId=` + accountId,
        method: 'get',
        headers: {
            'public-request': "true"
        },

    })
}
CrawlerService.crawlerUpdate = function (id, data) {

    return fetch({
        url: '/crawler/' + id,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}

CrawlerService.getLeads = function (options) {
    let queryString = bodyToQueryString(options);
    return fetch({
        url: '/crawler/' + options.crawlerId + `/leads?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },

    })
}

CrawlerService.duplicate = function (id) {

    return fetch({
        url: '/crawler/' + id + "/duplicate",
        method: 'put',
        headers: {
            'public-request': "true"
        },
    })
}
CrawlerService.delete = function (id) {

    return fetch({
        url: '/crawler/' + id,
        method: 'delete',
        headers: {
            'public-request': "true"
        },
    })
}
function cleanQuickSearchData(data) {
    const copy = { ...data, ...{} };
    delete copy.testResult;
    delete copy.sourceType;
    delete copy.added;
    delete copy.currentStep;
    delete copy.toCampaignName;
    if (copy.toCampaign == "")
        delete copy.toCampaign;
    return copy;
}
function bodyToQueryString(body) {
    return Object.keys(body).filter(
        key => body[key] !== undefined
    ).map(
        key => {
            if (body[key] !== undefined)
                return key + '=' + body[key]
            else
                return "";
        }
    ).join("&");
}
function cleanlinkedinCampaignCSVData(data) {
    const copy = { ...data, ...{} };
    delete copy.testResult;
    delete copy.nameError;
    delete copy.added;
    delete copy.csvFile;
    delete copy.currentStep;
    delete copy.toCampaignName;
    if (copy.toCampaign == "")
        delete copy.toCampaign;
    return copy;
}
export default CrawlerService;