import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { billingsError, billingsSuccess } from 'redux/actions/Billings';
import { DELETE_LICENSE, GET_INVOICES, GET_LICENSES, GET_LICENSES_STATS, PURCHASE_LICENSES } from 'redux/constants/Billings';
import BillingsService from 'services/BillingsService';

export function* getLicenses() {
    yield takeLatest(GET_LICENSES, function* (params) {
        try {
            const result = yield call(BillingsService.getLicenses, params.data);
            yield put(billingsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_LICENSES }));
        } catch (err) {
            yield put(billingsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_LICENSES }))
        }
    })
}

export function* purchaseLicenses() {
    yield takeLatest(PURCHASE_LICENSES, function* (params) {
        try {
            const result = yield call(BillingsService.purchaseLicenses, params.data);
            yield put(billingsSuccess({ result, success: true, isShowMessage: false, apiCall: PURCHASE_LICENSES }));
        } catch (err) {
            yield put(billingsError({ isShowMessage: true, success: false, message: err.message, apiCall: PURCHASE_LICENSES }))
        }
    })
}
export function* getLicensesStats() {
    yield takeLatest(GET_LICENSES_STATS, function* (params) {
        try {
            const result = yield call(BillingsService.getLicensesStats, params.data);
            yield put(billingsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_LICENSES_STATS }));
        } catch (err) {
            yield put(billingsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_LICENSES_STATS }))
        }
    })
}
export function* deleteLicense() {
    yield takeLatest(DELETE_LICENSE, function* (params) {
        try {
            const result = yield call(BillingsService.deleteLicense, params.data);
            yield put(billingsSuccess({ result, success: true, isShowMessage: true, message: "License deleted", apiCall: DELETE_LICENSE }));
        } catch (err) {
            yield put(billingsError({ isShowMessage: true, success: false, message: err.message, apiCall: DELETE_LICENSE }))
        }
    })
}
export function* getInvoices() {
    yield takeLatest(GET_INVOICES, function* (params) {
        try {
            const result = yield call(BillingsService.getInvoices, params.data);
            yield put(billingsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_INVOICES }));
        } catch (err) {
            yield put(billingsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_INVOICES }))
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(getLicenses),
        fork(purchaseLicenses),
        fork(getLicensesStats),
        fork(deleteLicense),
        fork(getInvoices),

    ]);
}