import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { GET_LIACCOUNT, UPDATE_LIACCOUNT, LIACCOUNT_ERROR, LIACCOUNT_SUCCESS, LIACCOUNT_VERIFY, LIACCOUNT_RESEND_CODE, LIACCOUNT_HIDE_MESSAGE, LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS, LIACCOUNT_SEND_INVITE, LIACCOUNT_LIST, UPDATE_LIACCOUNT_PASSWORD } from "../constants/LiAccount";
import { liAccountSuccess, liAccountError, liAccountGet, liAccountUpdate } from '../actions/LiAccount';
import Service from 'services/InAccountService';
export function* get() {
    yield takeLatest(GET_LIACCOUNT, function* (params) {
        try {
            const result = yield call(Service.data, params.id);
            yield put(liAccountSuccess({ result, success: true, isShowMessage: false }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export function* update() {
    yield takeLatest(UPDATE_LIACCOUNT, function* (params) {
        try {
            const result = yield call(Service.update, params.id, params.data);
            yield put(liAccountSuccess({ result, success: true, isShowMessage: false }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message }))
        }
    })
}

export function* updatePassword() {
    yield takeLatest(UPDATE_LIACCOUNT_PASSWORD, function* (params) {
        try {
            const result = yield call(Service.updatePassword, params.id, params.data);
            yield put(liAccountSuccess({ result, success: true, isShowMessage: true, message: "Updated", apiCall: UPDATE_LIACCOUNT_PASSWORD }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message, apiCall: UPDATE_LIACCOUNT_PASSWORD }))
        }
    })
}
export function* verify() {
    yield takeLatest(LIACCOUNT_VERIFY, function* (params) {
        try {
            const result = yield call(Service.verify, params);
            yield put(liAccountSuccess({ result, success: true, isShowMessage: false }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message }))
        }
    })
}
export function* resendCode() {
    yield takeLatest(LIACCOUNT_RESEND_CODE, function* (params) {
        try {
            const result = yield call(Service.resendCode, params.id);
            yield put(liAccountSuccess({ result, success: true, isShowMessage: true, message: "Code request sent to email." }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message }))
        }
    })
}
export function* updateNotificationSettings() {
    yield takeLatest(LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS, function* (params) {
        try {
            const result = yield call(Service.updateNotificationSettings, params.id, params.data);
            yield put(liAccountSuccess({ result: params.data, success: true, isShowMessage: false, apiCall: LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message, apiCall: LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS }))
        }
    })
}

export function* sendInvite() {
    yield takeLatest(LIACCOUNT_SEND_INVITE, function* (params) {
        try {
            const result = yield call(Service.sendInvite, params.id);
            yield put(liAccountSuccess({ result, success: true, isShowMessage: false }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message }))
        }
    })
}
export function* getAccounts() {
    yield takeLatest(LIACCOUNT_LIST, function* (params) {
        try {
            const result = yield call(Service.list, params.data);
            yield put(liAccountSuccess({ result, success: true, isShowMessage: false, apiCall: LIACCOUNT_LIST }));
        } catch (err) {
            yield put(liAccountError({ isShowMessage: true, success: false, message: err.message, apiCall: LIACCOUNT_LIST }))
        }
    })
}
export default function* rootSaga() {
    yield all([
        fork(get),
        fork(resendCode),
        fork(verify),
        fork(update),
        fork(updateNotificationSettings),
        fork(sendInvite),
        fork(getAccounts),
        fork(updatePassword),
    ]);
}