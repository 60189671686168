import fetch from 'auth/FetchInterceptor';

function bodyToQueryString(body) {
    return Object.keys(body).filter(
        key => body[key] !== undefined
    ).map(
        key => {
            if (body[key] !== undefined)
                return key + '=' + body[key]
            else
                return "";
        }
    ).join("&");
}

const BillingsService = {};
BillingsService.getLicenses = function (data) {
    const query = bodyToQueryString(data);
    return fetch({
        url: `/billings/licenses/?${query}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },
    })
}

BillingsService.purchaseLicenses = function (data) {
    return fetch({
        url: `/billings/licenses/`,
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data
    })
}

BillingsService.getLicensesStats = function () {
    return fetch({
        url: `/billings/licenses/stats`,
        method: 'get',
        headers: {
            'public-request': "true"
        },
    })
}


BillingsService.deleteLicense = function (id) {
    return fetch({
        url: `/billings/licenses/${id}`,
        method: 'delete',
        headers: {
            'public-request': "true"
        }
    })
}
BillingsService.getInvoices = function (data) {
    const query = bodyToQueryString(data);

    return fetch({
        url: `/stripe/invoices/?${query}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}
export default BillingsService;