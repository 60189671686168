import { GET_LIACCOUNT, UPDATE_LIACCOUNT, LIACCOUNT_ERROR, LIACCOUNT_SUCCESS, LIACCOUNT_HIDE_MESSAGE, LIACCOUNT_VERIFY, LIACCOUNT_RESEND_CODE, AUTOMATION_MENU_SETCURRENT, PROFILE_MENU_SETCURRENT, SETTINGS_MENU_SETCURRENT, LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS, LIACCOUNT_SEND_INVITE, LIACCOUNT_LIST, UPDATE_LIACCOUNT_PASSWORD } from "../constants/LiAccount";
const initialState = {
    success: undefined,
    loading: false,
    message: "",
    isShowMessage: false,
    liAccountData: { profile: {} },
    accountId: 0,
    pagination: {
        current: 1,
        pageSize: 10,
    },
    query: {
        sortBy: "createdAt",
        sortType: "descend",
    },
    accounts: []
}


export const LiAccount = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case GET_LIACCOUNT:
            return {
                ...state,
                loading: true,
                success: undefined
            }
        case LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS:
            return {
                ...state,
                loading: true
            }
        case UPDATE_LIACCOUNT:
            return {
                ...state,
                loading: true
            }
        case UPDATE_LIACCOUNT_PASSWORD:
            return {
                ...state,
                success: undefined,
            }
        case LIACCOUNT_SUCCESS:
            if (apiCall === LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS) {
                state.liAccountData.notificatinSettings = { ...state.liAccountData.notificatinSettings, ...result };
            }
            else if (apiCall == LIACCOUNT_SEND_INVITE) {
                state.liAccountData = result;
                state.accountId = result.id;
            }
            else if (apiCall == UPDATE_LIACCOUNT_PASSWORD) {
            }
            else if (apiCall == LIACCOUNT_LIST) {
                state.pagination.current = result.page;
                state.pagination.total = result.totalResults;
                state.pagination.pageSize = result.limit;
                state.records = result.results;
            }
            else {
                state.liAccountData = result;
                state.accountId = result.id;
            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case LIACCOUNT_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case LIACCOUNT_HIDE_MESSAGE:
            return {
                ...state,
                message: "",
                isShowMessage: false,
                success: undefined
            }
        case LIACCOUNT_VERIFY:
            return {
                ...state,
                loading: true
            }
        case LIACCOUNT_RESEND_CODE:
            return {
                ...state,
                loading: true
            }
        case LIACCOUNT_SEND_INVITE:
            return {
                ...state,
                loading: true, success: undefined
            }

        case LIACCOUNT_LIST:
            return {
                ...state,
                pagination: action.data.pagination,
                query: action.data.query,
                loading: true, success: undefined
            }

        default:
            return state
    }
}

const initialStateMenu = {
    automationMenuCurrent: "dashboard",
    profileMenuCurrent: "automation",
    settingsMenuCurrent: "integrations",
}
export const LiAccountMenu = (state = initialStateMenu, action) => {

    switch (action.type) {
        case AUTOMATION_MENU_SETCURRENT:
            return {
                ...state,
                automationMenuCurrent: action.id
            }
        case PROFILE_MENU_SETCURRENT:
            return {
                ...state,
                profileMenuCurrent: action.id,

            }
        case SETTINGS_MENU_SETCURRENT:
            return {
                ...state,
                settingsMenuCurrent: action.id
            }
        default:
            return state
    }
}
export default LiAccount;