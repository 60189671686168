import fetch from 'auth/FetchInterceptor';

function bodyToQueryString(body) {
    return Object.keys(body).filter(
        key => body[key] !== undefined
    ).map(
        key => {
            if (body[key] !== undefined)
                return key + '=' + body[key]
            else
                return "";
        }
    ).join("&");
}

const PostAlertsService = {};
PostAlertsService.getPostAlerts = function (data) {
    const query = bodyToQueryString(data);
    return fetch({
        url: `/post/alert/?${query}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },
    })
}
PostAlertsService.create = function (options) {
    const formData = new FormData();
    formData.append('csv', options.csv);
    formData.append("name", options.name);
    formData.append("accountId", options.accountId);
    return fetch({
        url: `/post/alert`,
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data: formData

    })
}
PostAlertsService.addProfileUrls = function (options) {
    const formData = new FormData();
    formData.append('csv', options.csv);
    return fetch({
        url: `/post/alert/${options.postAlertId}?accountId=${options.accountId}`,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data: formData

    })
}
PostAlertsService.getLeads = function (options) {
    let queryString = bodyToQueryString(options);
    return fetch({
        url: '/post/alert/' + options.postAlertId + `/leads?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },

    })
}
PostAlertsService.getActivities = function (options) {
    let queryString = bodyToQueryString(options);
    return fetch({
        url: '/post/alert/' + options.postAlertId + `/activities?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },

    })
}
export default PostAlertsService;