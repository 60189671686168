import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import {
    STRIPE_GET_SUBSCRIPTION_PLANS,
    STRIPE_SUBSCRIPTION_CANCEL, STRIPE_SUBSCRIPTION_CREATE, STRIPE_SUBSCRIPTION_SWITCH, STRIPE_SUBSCRIPTION_UPDATE
} from "redux/constants/Subscription";
import { stripeSubscriptionError, stripeSubscriptionSuccess } from '../actions/Subscription';
import Service from 'services/SubscriptionServiceStripe';
export function* stripePlans() {
    yield takeLatest(STRIPE_GET_SUBSCRIPTION_PLANS, function* () {
        try {
            const result = yield call(Service.plans);
            yield put(stripeSubscriptionSuccess({ result, success: true, isShowMessage: false, apiCall: STRIPE_GET_SUBSCRIPTION_PLANS }));
        } catch (err) {
            yield put(stripeSubscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export function* stripeCancel() {
    yield takeLatest(STRIPE_SUBSCRIPTION_CANCEL, function* (params) {
        try {
            const result = yield call(Service.cancel, params.inAccountId);
            yield put(stripeSubscriptionSuccess({
                result, success: true, isShowMessage: true,
                message: "Subscription cancelled.", apiCall: STRIPE_SUBSCRIPTION_CANCEL
            }));
        } catch (err) {
            yield put(stripeSubscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export function* stripeCreate() {
    yield takeLatest(STRIPE_SUBSCRIPTION_CREATE, function* (params) {
        try {
            const result = yield call(Service.subscribeToPlan, params.data);
            yield put(stripeSubscriptionSuccess({
                result, success: true, isShowMessage: true,
                message: "Subscription created.", apiCall: STRIPE_SUBSCRIPTION_CREATE
            }));
        } catch (err) {
            yield put(stripeSubscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export function* StripeSwitchPlan() {
    yield takeLatest(STRIPE_SUBSCRIPTION_SWITCH, function* (params) {
        try {
            const result = yield call(Service.switchPlan, params.inAccountId, params.newPlanId);
            yield put(stripeSubscriptionSuccess({
                result, success: true, isShowMessage: true,
                message: "Subscription updated.", apiCall: 'subscription'
            }));
        } catch (err) {
            yield put(stripeSubscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}

export function* stripeUpdate() {
    yield takeLatest(STRIPE_SUBSCRIPTION_UPDATE, function* (params) {
        try {
            const result = yield call(Service.resume, params.inAccountId);
            yield put(stripeSubscriptionSuccess({
                result, success: true, isShowMessage: true,
                message: "Subscription updated.", apiCall: STRIPE_SUBSCRIPTION_UPDATE
            }));
        } catch (err) {
            yield put(stripeSubscriptionError({ isShowMessage: true, success: false, message: err.message }))
        }
    })

}
export default function* rootSaga() {
    yield all([
        fork(stripePlans),
        fork(stripeCancel),
        fork(StripeSwitchPlan),
        fork(stripeUpdate),
        fork(stripeCreate),
    ]);
}