import { STRIPE_GET_SUBSCRIPTION_PLANS, STRIPE_SUBSCRIPTION_CANCEL, STRIPE_SUBSCRIPTION_CREATE, STRIPE_SUBSCRIPTION_ERROR, STRIPE_SUBSCRIPTION_HIDE_MESSAGE, STRIPE_SUBSCRIPTION_RESET_STATE, STRIPE_SUBSCRIPTION_SUCCESS, STRIPE_SUBSCRIPTION_SWITCH, STRIPE_SUBSCRIPTION_UPDATE } from "redux/constants/Subscription";

const initialState = {
    loading: false,
    message: "",
    isShowMessage: false,
    plans: [],
}


export const subscriptionStripe = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case STRIPE_SUBSCRIPTION_RESET_STATE:
            return JSON.parse(JSON.stringify(initialState));
        case STRIPE_GET_SUBSCRIPTION_PLANS:
            return {
                ...state,
                loading: true,
                success: undefined,
            }
        case STRIPE_SUBSCRIPTION_SWITCH:
            return {
                ...state,
                loading: true, success: undefined,

            }
        case STRIPE_SUBSCRIPTION_CANCEL:
            return {
                ...state,
                loading: true, success: undefined,

            }
        case STRIPE_SUBSCRIPTION_CREATE:
            return {
                ...state,
                loading: true, success: undefined,

            }
        case STRIPE_SUBSCRIPTION_UPDATE:
            return {
                ...state,
                loading: true, success: undefined,

            }
        case STRIPE_SUBSCRIPTION_SUCCESS:
            if (apiCall === STRIPE_GET_SUBSCRIPTION_PLANS) {
                state.plans = result.data;
            }
            else if (apiCall === STRIPE_SUBSCRIPTION_UPDATE) {
                state.subscription = result;
            }
            else if (apiCall === STRIPE_SUBSCRIPTION_CANCEL) {
                state.subscription = result;
            }
            else if (apiCall === STRIPE_SUBSCRIPTION_CREATE) {
                state.subscription = result;
                state.created = result.id;
            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case STRIPE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case STRIPE_SUBSCRIPTION_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }

        default:
            return state;
    }
}

export default subscriptionStripe;