import {
    STRIPE_SUBSCRIPTION_UPDATE,
    STRIPE_GET_SUBSCRIPTION_PLANS, STRIPE_GET_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_PLAN, GET_SUBSCRIPTION_PLANS, UPDATE_SUBSCRIPTION, SUBSCRIBE_TO_PLAN,
    SUBSCRIPTION_ERROR, SUBSCRIPTION_SUCCESS, SUBSCRIPTION_CANCEL, SUBSCRIPTION_SWITCH, SUBSCRIPTION_HIDE_MESSAGE, STRIPE_SUBSCRIPTION_SWITCH, STRIPE_SUBSCRIPTION_CANCEL, STRIPE_SUBSCRIPTION_SUCCESS, STRIPE_SUBSCRIPTION_ERROR, STRIPE_SUBSCRIPTION_HIDE_MESSAGE, STRIPE_SUBSCRIPTION_CREATE, STRIPE_SUBSCRIPTION_RESET_STATE
} from "redux/constants/Subscription";


export function subscriptionPlan(id) {
    return {
        type: GET_SUBSCRIPTION_PLAN,
        id
    };
}
export function subscriptionPlans() {
    return {
        type: GET_SUBSCRIPTION_PLANS,
    };
}
export function subscriptionSwitch(inAccountId, newPlanId) {
    return {
        type: SUBSCRIPTION_SWITCH,
        inAccountId, newPlanId
    };
}
export function subscriptionCancel(inAccountId) {
    return {
        type: SUBSCRIPTION_CANCEL,
        inAccountId
    };
}

export function subscriptionSuccess(data) {
    return {
        type: SUBSCRIPTION_SUCCESS,
        data
    }
}
export function subscriptionError(data) {
    return {
        type: SUBSCRIPTION_ERROR,
        data
    }
}
export function subscriptionHideMessage() {
    return {
        type: SUBSCRIPTION_HIDE_MESSAGE,

    }
}
// STRIPES ACTIONS
export function stripeSubscriptionPlan(id) {
    return {
        type: STRIPE_GET_SUBSCRIPTION_PLAN,
        id
    };
}
export function stripeSubscriptionPlans() {
    return {
        type: STRIPE_GET_SUBSCRIPTION_PLANS,
    };
}
export function stripeSubscriptionSwitch(inAccountId, newPlanId) {
    return {
        type: STRIPE_SUBSCRIPTION_SWITCH,
        inAccountId, newPlanId
    };
}
export function stripeSubscriptionCancel(inAccountId) {
    return {
        type: STRIPE_SUBSCRIPTION_CANCEL,
        inAccountId
    };
}

export function stripeSubscriptionSuccess(data) {
    return {
        type: STRIPE_SUBSCRIPTION_SUCCESS,
        data
    }
}
export function stripeSubscriptionError(data) {
    return {
        type: STRIPE_SUBSCRIPTION_ERROR,
        data
    }
}
export function stripeSubscriptionHideMessage() {
    return {
        type: STRIPE_SUBSCRIPTION_HIDE_MESSAGE,

    }
}

export function stripeSubscriptionUpdate(inAccountId) {
    return {
        type: STRIPE_SUBSCRIPTION_UPDATE,
        inAccountId
    };
}
export function stripeSubscriptionCreate(data) {
    return {
        type: STRIPE_SUBSCRIPTION_CREATE,
        data
    };
}
export function stripeSubscriptionResetState() {
    return {
        type: STRIPE_SUBSCRIPTION_RESET_STATE
    };
}
