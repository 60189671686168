import {
    QUICK_SEARCH_SET_STEP, QUICK_SEARCH_SET_WIZARD_STATE, QUICK_SEARCH_HIDE_MESSAGE,
    QUICK_SEARCH_SET_WIZARD_EMAIL, QUICK_SEARCH_SET_WIZARD, CRAWLER_LIST, QUICK_SEARCH_INIT, QUICK_SEARCH_TEST, QUICK_SEARCH_SUCCESS, QUICK_SEARCH_ERROR, QUICK_SEARCH_ADD, QUICK_SEARCH_LIST,
    QUICK_SEARCH_DELETE, CRAWLER_GET, CRAWLER_ERROR, CRAWLER_HIDE_MESSAGE, CRAWLER_SUCCESS, CRAWLER_SET_STATE,
    CRAWLER_UPDATE,
    CRAWLER_DELETE,
    CRAWLER_DUPLICATE
} from "redux/constants/Crawler";
import { CRAWLER_LEAD_ERROR, CRAWLER_LEAD_HIDE_MESSAGE, CRAWLER_LEAD_LIST, CRAWLER_LEAD_RESET, CRAWLER_LEAD_SUCCESS } from "redux/constants/SearchLead";
export function quickSearchSetStep(data) {
    return {
        type: QUICK_SEARCH_SET_STEP,
        data
    }
}
export function quickSearchSetWizardState(data) {
    return {
        type: QUICK_SEARCH_SET_WIZARD_STATE,
        data
    }
}
export function quickSearchSetWizardAddEmail(data) {
    return {
        type: QUICK_SEARCH_SET_WIZARD_EMAIL,
        data
    }
}
export function quickSearchSetWizard(data, property) {
    return {
        type: QUICK_SEARCH_SET_WIZARD,
        data,
        property
    }
}
export function quickSearchTest(data) {
    return {
        type: QUICK_SEARCH_TEST,
        data
    }
}
export function quickSearchSuccess(data) {
    return {
        type: QUICK_SEARCH_SUCCESS,
        data,
    }
}
export function quickSearchInit(data) {
    return {
        type: QUICK_SEARCH_INIT,
        data
    }
}
export function quickSearchError(data) {
    return {
        type: QUICK_SEARCH_ERROR,
        data

    }
}
export function quickSearchHideMessage() {
    return {
        type: QUICK_SEARCH_HIDE_MESSAGE,
    }
}

export function quickSearchAdd(data) {
    return {
        type: QUICK_SEARCH_ADD,
        data

    }
}
export function quickSearchUpdate(id, data) {
    return {
        type: QUICK_SEARCH_ADD,
        data,
        id

    }
}
export function quickSearchList(accountId) {
    return {
        type: QUICK_SEARCH_LIST,
        accountId

    }
}

export function quickSearchDelete(id) {
    return {
        type: QUICK_SEARCH_DELETE,
        id

    }
}

export function crawlerGet(data) {
    return {
        type: CRAWLER_GET,
        data
    }
}
export function crawlerList(accountId) {
    return {
        type: CRAWLER_LIST,
        accountId
    }
}

export function crawlerSuccess(data) {
    return {
        type: CRAWLER_SUCCESS,
        data,
    }
}
export function crawlerHideMessage() {
    return {
        type: CRAWLER_HIDE_MESSAGE,
    }
}
export function crawlerError(data) {
    return {
        type: CRAWLER_ERROR,
        data

    }
}
export function crawlerSetState(data) {
    return {
        type: CRAWLER_SET_STATE,
        data

    }
}
export function crawlerUpdate(id, data) {
    return {
        type: CRAWLER_UPDATE,
        data,
        id
    }
}

export function crawlerGetLeads(data) {
    return {
        type: CRAWLER_LEAD_LIST,
        data

    }
}

export function crawlerResetLeads(data) {
    return {
        type: CRAWLER_LEAD_RESET,
        data

    }
}
export function crawlerGetLeadsError(data) {
    return {
        type: CRAWLER_LEAD_ERROR,
        data

    }
}

export function crawlerGetLeadsSuccess(data) {
    return {
        type: CRAWLER_LEAD_SUCCESS,
        data,
    }
}
export function crawlerGetLeadsHideMessage() {
    return {
        type: CRAWLER_LEAD_HIDE_MESSAGE,
    }
}
export function crawlerDuplicate(data) {
    return {
        type: CRAWLER_DUPLICATE,
        data
    }
}
export function crawlerDelete(data) {
    return {
        type: CRAWLER_DELETE,
        data
    }
}