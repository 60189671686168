import { MESSAGE_TEMPLATE_ERROR, MESSAGE_TEMPLATE_NEW, MESSAGE_TEMPLATE_DELETE, MESSAGE_TEMPLATE_GET, MESSAGE_TEMPLATE_HIDE_MESSAGE, MESSAGE_TEMPLATE_LIST, MESSAGE_TEMPLATE_SUCCESS, MESSAGE_TEMPLATE_UPDATE } from "redux/constants/MessageTemplate"
const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    templates: [],
    pagination: {
        current: 1,
        pageSize: 10,
    },
    query: {
        sortBy: "createdAt",
        sortType: "descend",
        accountId: 0,

    }
}

export const MessageTemplate = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {

        case MESSAGE_TEMPLATE_LIST:
            state.query = { ...state.query, ...action.data };

            return {
                ...state,
                loading: true, isShowMessage: false, templates: []
            }
        case MESSAGE_TEMPLATE_GET:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case MESSAGE_TEMPLATE_UPDATE:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case MESSAGE_TEMPLATE_NEW:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }

        case MESSAGE_TEMPLATE_DELETE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }

        case MESSAGE_TEMPLATE_SUCCESS:
            if (apiCall === MESSAGE_TEMPLATE_LIST)
                state.templates = result;

            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case MESSAGE_TEMPLATE_ERROR:

            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case MESSAGE_TEMPLATE_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }

        default:
            return state;
    }
}

export default MessageTemplate;