export const CAMPAIGN_SEQUENCE_PROFILE_ENGAGE = "CAMPAIGN_SEQUENCE_PROFILE_ENGAGE";
export const CAMPAIGN_SEQUENCE_SEND_INMAIL = "CAMPAIGN_SEQUENCE_SEND_INMAIL";
export const CAMPAIGN_SEQUENCE_SEND_INVITE = "CAMPAIGN_SEQUENCE_SEND_INVITE";
export const CAMPAIGN_SEQUENCE_SEND_MESSAGE = "CAMPAIGN_SEQUENCE_SEND_MESSAGE";
export class ProfileEngage {
    viewLinkedinProfile = false
    follow = false;
    endorseTopSkills = false;
    endorseTopSkillsCount = 1;
    likeRecentPosts = false;
    likeRecentPostsCount = 1;
    addTags = false;
    type = CAMPAIGN_SEQUENCE_PROFILE_ENGAGE;
    tags = [];
    scheduleHour = 1;
    scheduleMultiplier = 1;
    title = "";
    constructor(data) {
        Object.keys(data).forEach(key => {
            if (this[key] != undefined)
                this[key] = data[key];
        });
    }
}

export class SendInvite {
    showWelcome = false;
    inviteMessage = "";
    welcomeMessage = ""
    type = CAMPAIGN_SEQUENCE_SEND_INVITE;
    tags = [];
    scheduleHour = 1;
    scheduleMultiplier = 1;
    title = "";
    constructor(data) {
        Object.keys(data).forEach(key => {
            if (this[key] != undefined)
                this[key] = data[key];
        });
    }
}

export class SendMessage {
    type = CAMPAIGN_SEQUENCE_SEND_MESSAGE;
    tags = [];
    scheduleHour = 1;
    scheduleMultiplier = 1;
    message = "";
    title = "";
    constructor(data) {
        Object.keys(data).forEach(key => {
            if (this[key] != undefined)
                this[key] = data[key];
        });
    }
}


export class CampaignSequence {
    constructor(data) {
        let props;
        if (data.type === CAMPAIGN_SEQUENCE_PROFILE_ENGAGE)
            props = new ProfileEngage(data);
        else if (data.type === CAMPAIGN_SEQUENCE_SEND_INVITE)
            props = new SendInvite(data);
        else if (data.type === CAMPAIGN_SEQUENCE_SEND_MESSAGE)
            props = new SendMessage(data);
        Object.keys(props).forEach(key => {
            this[key] = props[key];
        });
    }
}