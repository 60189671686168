import axios from 'axios'
import { API_BASE_URL } from 'configs/AppConfig'
import history from '../history'
import { AUTH_TOKEN } from 'redux/constants/Auth'
import { notification } from 'antd';
import StorageService from 'services/StorageService';
const service = axios.create({
    baseURL: API_BASE_URL,
    timeout: 60000
})

// Config
const ENTRY_ROUTE = '/auth/login'
const TOKEN_PAYLOAD_KEY = 'authorization'
const PUBLIC_REQUEST_KEY = 'public-request'

// API Request interceptor
service.interceptors.request.use(config => {
    const { access } = StorageService.get(AUTH_TOKEN);
    if (access && access.token) {
        config.headers[TOKEN_PAYLOAD_KEY] = "Bearer " + access.token
    }

    if (!access && !config.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE)
        window.location.reload();
    }

    return config
}, error => {
    // Do something with request error here
    notification.error({
        message: 'Error'
    })
    Promise.reject(error)
})

// API respone interceptor
service.interceptors.response.use((response) => {
    return response.data
}, (error) => {

    let notificationParam = {
        message: ''
    }

    // Remove token and redirect

    if (error.response.status === 400 && error.config.url == ENTRY_ROUTE) {
        notificationParam.message = error.response.data.message;
    }
    else if (error.response.status === 400 && (window.location.pathname == "/auth/register" || window.location.pathname == "/auth/verify-email/" || window.location.pathname == "/auth/verify-email"
        || window.location.pathname == "/auth/reset-password" || window.location.pathname == "/auth/verify-password/"
    )) {
        notificationParam.message = error.response.data.message;

    }
    else if (error.response.status === 403 && error.response.statusText == "Forbidden") {
        notificationParam.message = 'Forbidden'
        notificationParam.description = 'Forbidden'

    }
    else if (error.response.status === 403 || (error.response.status === 401 && error.response.message == "Please authenticate")
        || (error.response.status === 401 && error.response.statusText == "Unauthorized" && error.response?.data?.message == "Please authenticate")) {
        notificationParam.message = 'Authentication Fail'
        notificationParam.description = 'Please login again'
        localStorage.removeItem(AUTH_TOKEN)
        history.push(ENTRY_ROUTE)
        window.location.reload();
    }

    if (error.response.status === 404) {
        notificationParam.message = 'Not Found'
    }

    if (error.response.status === 500) {
        notificationParam.message = 'Internal Server Error'
    }

    if (error.response.status === 508) {
        notificationParam.message = 'Time Out'
    }

    //notification.error(notificationParam)

    return Promise.reject(error.response.data || error);
});

export default service