export const GET_POST_ALERTS = "GET_POST_ALERTS";
export const UPLOAD_POST_ALERTS_CSV = "UPLOAD_POST_ALERTS_CSV";
export const GET_POST_ALERTS_PROFILE_URLS = "GET_POST_ALERTS_PROFILE_URLS";
export const POST_ALERTS_SUCCESS = "POST_ALERTS_SUCCESS";
export const POST_ALERTS_ERROR = "POST_ALERTS_ERROR";
export const POST_ALERTS_LEAD_RESET = "POST_ALERTS_LEAD_RESET";
export const POST_ALERTS_LEAD_INIT = "POST_ALERTS_LEAD_INIT";
export const POST_ALERTS_LEAD_SUCCESS = "POST_ALERTS_LEAD_SUCCESS";
export const POST_ALERTS_LEAD_ERROR = "POST_ALERTS_LEAD_ERROR";
export const POST_ALERTS_LEAD_HIDE_MESSAGE = "POST_ALERTS_LEAD_HIDE_MESSAGE";
export const GET_POST_ALERTS_LEADS = "GET_POST_ALERTS_LEADS";

export const POST_ALERTS_HIDE_MESSAGE = "POST_ALERTS_HIDE_MESSAGE";
export const POST_ALERTS_SHOW_MESSAGE = "POST_ALERTS_SHOW_MESSAGE";