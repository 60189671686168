
import { CRAWLER_LIST, CRAWLER_GET, CRAWLER_SUCCESS, CRAWLER_HIDE_MESSAGE, CRAWLER_ERROR, CRAWLER_SET_STATE, CRAWLER_UPDATE, CRAWLER_DELETE, CRAWLER_DUPLICATE } from "redux/constants/Crawler";
const initialStateCrawler = {
    loading: false,
    message: "",
    isShowMessage: false,
    crawlers: [],
    updateCaller: "",
    collapses: {
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0
    },
    pagination: {
        current: 1,
        pageSize: 10,
    },
    currentCrawler: {},
    lastAction: "",
    callerId: "",
    currentCrawlerLastState: {}
}
export const Crawler = (state = initialStateCrawler, action) => {
    let currentCrawler;
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case CRAWLER_LIST:
            return {
                ...state,
                loading: true
            }
        case CRAWLER_GET:
            return {
                ...state,
                loading: true
            }
        case CRAWLER_UPDATE:
            state.success = undefined;
            state.callerId = action.id;
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case CRAWLER_SUCCESS:
            state.lastAction = apiCall;
            if (apiCall === CRAWLER_LIST) {
                state.pagination.current = result.page;
                state.pagination.total = result.totalResults;
                state.pagination.pageSize = result.limit;
                state.crawlers = result;
            }
            else if (apiCall === CRAWLER_GET)
                state.currentCrawler = result;
            else if (apiCall === CRAWLER_UPDATE)
                state.currentCrawler = { ...state.currentCrawler, ...result };
            else if (apiCall === CRAWLER_DELETE) {
                state.crawlers.results = state.crawlers.results.filter(item => item.id != state.callerId);
                state.crawlers.totalResults -= 1;

            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case CRAWLER_ERROR:
            if (apiCall === CRAWLER_UPDATE)
                currentCrawler = { ...{}, ...state.currentCrawlerLastState };
            return {
                currentCrawler,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case CRAWLER_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }
        case CRAWLER_SET_STATE: {
            let temp;
            if (action.data.collapses)
                temp = { collapses: action.data.collapses };
            else
                temp = { currentCrawler: { ...state.currentCrawler, ...action.data } };
            return {
                ...state,
                ...temp
            }
        }
        case CRAWLER_DELETE:
            state.success = undefined;
            state.callerId = action.data;
            return {
                ...state,
                loading: true
            }
        case CRAWLER_DUPLICATE:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}

export default Crawler;