
export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const CAMPAIGN_GET = "CAMPAIGN_GET";
export const CAMPAIGN_ERROR = "CAMPAIGN_ERROR";
export const CAMPAIGN_SUCCESS = "CAMPAIGN_SUCCESS";
export const CAMPAIGN_HIDE_MESSAGE = "CAMPAIGN_HIDE_MESSAGE";
export const CAMPAIGN_SET_STATE = "CAMPAIGN_SET_STATE";
export const CAMPAIGN_UPDATE = "CAMPAIGN_UPDATE";
export const CAMPAIGN_ADD_LEAD_SOURCE = "CAMPAIGN_ADD_LEAD_SOURCE";
export const CAMPAIGN_SOURCE_TEST = "CAMPAIGN_SOURCE_TEST";
export const CAMPAIGN_ARCHIVE = "CAMPAIGN_ARCHIVE";
export const CAMPAIGN_DELETE = "CAMPAIGN_DELETE";
export const CAMPAIGN_DUPLICATE = "CAMPAIGN_DUPLICATE";
export const CAMPAIGN_RESET = "CAMPAIGN_RESET";
export const CAMPAIGN_INIT = "CAMPAIGN_INIT";
export const CAMPAIGN_SET_QUERY = "CAMPAIGN_SET_QUERY";
export const CAMPAIGN_SAVE_AS_TEMPLATE = "CAMPAIGN_SAVE_AS_TEMPLATE";

