
export const INBOX_GET = "INBOX_GET";
export const INBOX_SALES_GET = "INBOX_SALES_GET";
export const INBOX_ERROR = "INBOX_ERROR";
export const INBOX_SUCCESS = "INBOX_SUCCESS";
export const INBOX_HIDE_MESSAGE = "INBOX_HIDE_MESSAGE";
export const INBOX_SET_STATE = "INBOX_SET_STATE";
export const INBOX_INIT = "INBOX_INIT";
export const INBOX_RESET = "INBOX_RESET";
export const INBOX_SET_QUERY = "INBOX_SET_QUERY";
export const INBOX_SET_QUERY_SALES = "INBOX_SET_QUERY_SALES";
export const INBOX_SET_TYPE = "INBOX_SET_TYPE";

