import {
    GET_SYSTEM_OPTIONS, GET_ACCOUNT_OPTIONS, GET_SYSTEM_OPTIONS_ERROR, GET_SYSTEM_OPTIONS_SUCCESS, GET_SYSTEM_CAMPAIGN_OPTIONS, GET_SYSTEM_CONTACT_OPTIONS
} from "redux/constants/System";
import { all, put, fork, call, takeLatest } from 'redux-saga/effects';

import Service from 'services/SystemService';
import { systemOptionsError, systemOptionsSuccess } from 'redux/actions/System';
export function* campaignOptions() {
    yield takeLatest(GET_SYSTEM_CAMPAIGN_OPTIONS, function* (params = {}) {
        try {
            const result = yield call(Service.getCampaignOptions, params);
            yield put(systemOptionsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_SYSTEM_CAMPAIGN_OPTIONS }));
        } catch (err) {
            yield put(systemOptionsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_SYSTEM_CAMPAIGN_OPTIONS }))
        }
    })

}
export function* contactOptions() {
    yield takeLatest(GET_SYSTEM_CONTACT_OPTIONS, function* (params = {}) {
        try {
            const result = yield call(Service.getContactOptions, params);
            yield put(systemOptionsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_SYSTEM_CONTACT_OPTIONS }));
        } catch (err) {
            yield put(systemOptionsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_SYSTEM_CONTACT_OPTIONS }))
        }
    })

}
export function* accountOptions() {
    yield takeLatest(GET_ACCOUNT_OPTIONS, function* (params = {}) {
        try {
            const result = yield call(Service.getAccountOptions, params);
            yield put(systemOptionsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_ACCOUNT_OPTIONS }));
        } catch (err) {
            yield put(systemOptionsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_ACCOUNT_OPTIONS }))
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(campaignOptions),
        fork(accountOptions),
        fork(contactOptions),

    ]);
}