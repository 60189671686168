import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import {
    CONTACT_LIST, CONTACT_GET, CONTACT_ERROR, CONTACT_DELETE,
    CONTACT_HIDE_MESSAGE, CONTACT_SUCCESS, CONTACT_UPDATE, CONTACT_SET_QUERY, CONTACT_GET_LINKEDIN_CHAT, CONTACT_SEND_LINKEDIN_CHAT, CONTACT_ADD_NOTE, CONTACT_DELETE_NOTE, CONTACT_ADD_TAG, CONTACT_DELETE_TAG, CONTACT_CSV_DOWNLOAD,
} from "redux/constants/Contacts";
import Service from 'services/ContactService';
import { contactSuccess, contactError } from 'redux/actions/Contacts';
export function* contactList() {
    yield takeLatest(CONTACT_LIST, function* (params) {
        try {
            const result = yield call(Service.contactList, params.data);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_LIST }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_LIST }))
        }
    })

}
export function* contactUpdate() {
    yield takeLatest(CONTACT_UPDATE, function* (params) {
        try {
            const result = yield call(Service.contactUpdate, params.id, params.contactId, params.data);
            result.id = params.id;
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_UPDATE }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_UPDATE }))
        }
    })

}
export function* contactGet() {
    yield takeLatest(CONTACT_GET, function* (params) {
        try {

            const result = yield call(Service.contactGet, params.data);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_GET }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_GET }))
        }
    })

}

export function* contactAddNote() {
    yield takeLatest(CONTACT_ADD_NOTE, function* (params) {
        try {

            const result = yield call(Service.contactAddNote, params.id, params.contactId, params.data);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_ADD_NOTE }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_ADD_NOTE }))
        }
    })

}

export function* contactDeleteNote() {
    yield takeLatest(CONTACT_DELETE_NOTE, function* (params) {
        try {

            const result = yield call(Service.contactDeleteNote, params.id, params.contactId, params.data);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_DELETE_NOTE }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_DELETE_NOTE }))
        }
    })

}
export function* contactAddTag() {
    yield takeLatest(CONTACT_ADD_TAG, function* (params) {
        try {

            const result = yield call(Service.contactAddTag, params.id, params.contactId, params.data);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_ADD_TAG }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_ADD_TAG }))
        }
    })

}

export function* contactDeleteTag() {
    yield takeLatest(CONTACT_DELETE_TAG, function* (params) {
        try {

            const result = yield call(Service.contactDeleteTag, params.id, params.contactId, params.data);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_DELETE_TAG }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_DELETE_TAG }))
        }
    })

}
export function* contactGetLinkedinChat() {
    yield takeLatest(CONTACT_GET_LINKEDIN_CHAT, function* (params) {
        try {

            const result = yield call(Service.contactGetLinkedinChat, params.id, params.accountId, params.salesInbox);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_GET_LINKEDIN_CHAT }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_GET_LINKEDIN_CHAT }))
        }
    })

}
export function* contactSendLinkedinChat() {
    yield takeLatest(CONTACT_SEND_LINKEDIN_CHAT, function* (params) {
        try {

            const result = yield call(Service.contactSendLinkedinChat, params.id, params.accountId, params.data, params.salesInbox);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_SEND_LINKEDIN_CHAT }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_SEND_LINKEDIN_CHAT }))
        }
    })

}

export function* contactCSVDownload() {
    yield takeLatest(CONTACT_CSV_DOWNLOAD, function* (params) {
        try {

            const result = yield call(Service.contactCSVDownload, params.data);
            yield put(contactSuccess({ result, success: true, isShowMessage: false, apiCall: CONTACT_CSV_DOWNLOAD }));
        } catch (err) {
            yield put(contactError({ isShowMessage: true, success: false, message: err.message, apiCall: CONTACT_CSV_DOWNLOAD }))
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(contactList),
        fork(contactCSVDownload),
        fork(contactUpdate),
        fork(contactGet),
        fork(contactAddNote),
        fork(contactDeleteNote),
        fork(contactAddTag),
        fork(contactDeleteTag),
        fork(contactGetLinkedinChat),
        fork(contactSendLinkedinChat),


    ]);
}