import { MESSAGE_TEMPLATE_ERROR, MESSAGE_TEMPLATE_NEW, MESSAGE_TEMPLATE_DELETE, MESSAGE_TEMPLATE_GET, MESSAGE_TEMPLATE_HIDE_MESSAGE, MESSAGE_TEMPLATE_LIST, MESSAGE_TEMPLATE_SUCCESS, MESSAGE_TEMPLATE_UPDATE } from "redux/constants/MessageTemplate"


export function messageTemplateGet(data) {
    return {
        type: MESSAGE_TEMPLATE_GET,
        data
    }
}
export function messageTemplateList(data) {
    return {
        type: MESSAGE_TEMPLATE_LIST,
        data
    }
}
export function messageTemplateSuccess(data) {
    return {
        type: MESSAGE_TEMPLATE_SUCCESS,
        data,
    }
}
export function messageTemplateHideMessage() {
    return {
        type: MESSAGE_TEMPLATE_HIDE_MESSAGE,
    }
}
export function messageTemplateError(data) {
    return {
        type: MESSAGE_TEMPLATE_ERROR,
        data

    }
}

export function messageTemplateUpdate(id, messageTemplateId, data) {
    return {
        type: MESSAGE_TEMPLATE_UPDATE,
        data,
        id, messageTemplateId
    }
}
export function messageTemplateDelete(id) {
    return {
        type: MESSAGE_TEMPLATE_DELETE,
        id
    }
}
export function messageTemplateNew(id) {
    return {
        type: MESSAGE_TEMPLATE_NEW,
        id
    }
}