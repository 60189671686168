import { quickSearchSuccess } from "redux/actions";
import {
    QUICK_SEARCH_SET_STEP, QUICK_SEARCH_SET_WIZARD_STATE, QUICK_SEARCH_HIDE_MESSAGE, QUICK_SEARCH_DELETE, QUICK_SEARCH_UPDATE,
    QUICK_SEARCH_SET_WIZARD_EMAIL, CRAWLER_GET, QUICK_SEARCH_SET_WIZARD, QUICK_SEARCH_INIT, QUICK_SEARCH_TEST, QUICK_SEARCH_SUCCESS, QUICK_SEARCH_ERROR, QUICK_SEARCH_ADD, QUICK_SEARCH_LIST, CRAWLER_LIST
} from "redux/constants/Crawler";
const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    currentStep: 0,
    wizardState: {
        crawlerStart: "immediate",
        notifications: false,
        autoDelivery: false,
        deliverOnFinish: ["email"],
        notifyOnFinish: ["email"],
        notifyOnError: ["email"],
        notifyOnFinishEmails: [],
        notifyOnErrorEmails: [],
        autoDeliveryEmails: [],
        deliveryFormat: "csv",
        name: "",
        systemFilters: [],
        nameError: false,
        added: false,
        searchUrl: "",
        maximumAmountResult: 0
    },
    crawlers: []
}


export const QuickSearch = (state = JSON.parse(JSON.stringify(initialState)), action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {

        case QUICK_SEARCH_LIST:
            return {
                ...state,
                loading: true
            }
        case QUICK_SEARCH_DELETE:
            return {
                ...state,
                loading: true
            }
        case QUICK_SEARCH_UPDATE:
            return {
                ...state,
                loading: true
            }
        case QUICK_SEARCH_ADD:
            return {
                ...state,
                loading: true
            }
        case QUICK_SEARCH_INIT:
            if (action.data)
                state = action.data;
            else
                state = JSON.parse(JSON.stringify(initialState));
            return state;
        case QUICK_SEARCH_TEST:
            state.wizardState.searchUrl = action.data.url;
            return {
                ...state,
                loading: true
            }
        case QUICK_SEARCH_SUCCESS:
            if (apiCall === QUICK_SEARCH_TEST) {
                state.wizardState.testResult = result;
                state.wizardState.maximumAmountResult = result.totalCount;
            }

            else if (apiCall === QUICK_SEARCH_ADD) {
                state.wizardState.added = true;
            }

            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case QUICK_SEARCH_SET_STEP:
            return {
                ...state,
                currentStep: action.data
            }
        case QUICK_SEARCH_SET_WIZARD_STATE:
            const wizardState = { ...state.wizardState, ...action.data };
            return {
                ...state,
                wizardState
            };
        case QUICK_SEARCH_SET_WIZARD_EMAIL:
            if (state.wizardState.notifyOnFinishEmails.indexOf(action.data) === -1)
                state.wizardState.notifyOnFinishEmails.push(action.data);
            if (state.wizardState.notifyOnErrorEmails.indexOf(action.data) === -1)
                state.wizardState.notifyOnErrorEmails.push(action.data)
            if (state.wizardState.autoDeliveryEmails.indexOf(action.data) === -1)
                state.wizardState.autoDeliveryEmails.push(action.data)

            return {
                ...state,
                wizardState: state.wizardState
            };
        case QUICK_SEARCH_ERROR:
            if (apiCall === QUICK_SEARCH_TEST)
                delete state.wizardState.testResult;
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case QUICK_SEARCH_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }

        default:
            return state;
    }
}


export default QuickSearch;