export const GET_LICENSES = "GET_LICENSES";
export const GET_LICENSES_STATS = "GET_LICENSES_STATS";
export const PURCHASE_LICENSES = "PURCHASE_LICENSES";
export const DELETE_LICENSE = "DELETE_LICENSE";
export const BILLINGS_SUCCESS = "BILLINGS_SUCCESS";
export const BILLINGS_ERROR = "BILLINGS_ERROR";
export const BILLINGS_RESET = "BILLINGS_RESET";
export const BILLINGS_INIT = "BILLINGS_INIT";
export const BILLINGS_HIDE_MESSAGE = "BILLINGS_HIDE_MESSAGE";
export const BILLINGS_SHOW_MESSAGE = "BILLINGS_SHOW_MESSAGE";
export const GET_INVOICES = "GET_INVOICES";