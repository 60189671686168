import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import LiAccounts from "./liAccounts";
export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/accounts`} component={lazy(() => import(`./accounts`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/liAccounts`} component={lazy(() => import(`./liAccounts`))} />
        <Route path={`${APP_PREFIX_PATH}/templates`} component={lazy(() => import(`./messageTemplates`))} />
        <Route path={`${APP_PREFIX_PATH}/billings`} component={lazy(() => import(`./licenseBillings`))} />
        <Route path={`${APP_PREFIX_PATH}/`} component={lazy(() => import(`./liAccounts`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/liAccounts`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
