import {
  InboxOutlined, RobotOutlined, NotificationOutlined, CodeOutlined,
  PieChartOutlined, AppstoreOutlined, GroupOutlined, CreditCardOutlined, ContactsOutlined, CloudDownloadOutlined, HistoryOutlined,
  SettingOutlined, FileProtectOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const adminMenu = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: AppstoreOutlined,
  breadcrumb: true,
  submenu: []
},

{
  key: 'accounts',
  path: `${APP_PREFIX_PATH}/accounts`,
  title: 'sidenav.accounts',
  icon: RobotOutlined,
  breadcrumb: false,
  submenu: []
},

]
const managerMenu = [

  {
    key: 'accounts',
    path: `${APP_PREFIX_PATH}/liAccounts`,
    title: 'sidenav.accounts',
    icon: RobotOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'templates',
    path: `${APP_PREFIX_PATH}/templates`,
    title: 'Templates',
    icon: GroupOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'Settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: []
  },

]

const managerMenuWhiteLabel = [

  {
    key: 'accounts',
    path: `${APP_PREFIX_PATH}/liAccounts`,
    title: 'sidenav.accounts',
    icon: RobotOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'templates',
    path: `${APP_PREFIX_PATH}/templates`,
    title: 'Templates',
    icon: GroupOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'Settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: []
  },
  {
    key: 'billings1',
    path: ``,
    breadcrumb: false,
    icon: CreditCardOutlined,
    title: 'Billings',

    submenu: [
      {
        key: 'Licenses',
        path: `${APP_PREFIX_PATH}/billings`,
        title: 'Licenses',
        icon: CreditCardOutlined,
        breadcrumb: false, submenu: []
      },
      {
        key: 'invoices',
        path: `${APP_PREFIX_PATH}/billings/invoices`,
        title: 'Invoices',
        icon: FileProtectOutlined,
        breadcrumb: false, submenu: []
      }
    ]
  },
]
const clientMenu = [{
  key: 'analytics',
  path: `${APP_PREFIX_PATH}/liAccounts/automation/analytics`,
  title: 'Analytics',
  icon: PieChartOutlined,
  breadcrumb: false,
  submenu: []
},

{
  key: 'contacts',
  path: `${APP_PREFIX_PATH}/liAccounts/automation/contacts`,
  title: 'Contacts',
  icon: ContactsOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'inbox',
  path: `${APP_PREFIX_PATH}/liAccounts/automation/inbox`,
  title: 'Inbox',
  icon: InboxOutlined,
  breadcrumb: false,
  submenu: []
},

{
  key: 'downloads',
  path: `${APP_PREFIX_PATH}/liAccounts/automation/downloads`,
  title: 'Downloads',
  icon: CloudDownloadOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'activities',
  path: `${APP_PREFIX_PATH}/liAccounts/automation/activities`,
  title: 'Activities',
  icon: HistoryOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'notifications',
  path: `${APP_PREFIX_PATH}/liAccounts/settings/notifications`,
  title: 'Notifications',
  icon: NotificationOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'bots',
  path: `${APP_PREFIX_PATH}/liAccounts/settings/bots`,
  title: 'Bots',
  icon: CodeOutlined,
  breadcrumb: false,
  submenu: []
},

]
const navigationConfig = [
  ...adminMenu
]

export const navigationConfigManager = [...managerMenu];
export const navigationConfigCLient = [...clientMenu];
export const navigationConfigManagerWhiteLabel = [...managerMenuWhiteLabel];
export default navigationConfig;
