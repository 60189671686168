import { CRAWLER_LEAD_ERROR, CRAWLER_LEAD_HIDE_MESSAGE, CRAWLER_LEAD_INIT, CRAWLER_LEAD_LIST, CRAWLER_LEAD_RESET, CRAWLER_LEAD_SUCCESS } from "redux/constants/SearchLead";
import { merge } from "lodash";

const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    contacts: [],
    currentContact: {},
    refreshId: 0,
    pagination: {
        current: 1,
        pageSize: 10,
        total: 10
    },
    query: {
        sortBy: "createdAt",
        sortType: "descend",
        accountId: 0,
        //   contactStatus: "connection",
        //  status: []
    },
    currentAction: ""
}

export const CrawlerLead = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case CRAWLER_LEAD_RESET:
            return JSON.parse(JSON.stringify(initialState));
        case CRAWLER_LEAD_INIT:
            return merge(JSON.parse(JSON.stringify(initialState)), action.data)
        case CRAWLER_LEAD_LIST:
            state.query = { ...state.query, ...action.data };
            return {
                ...state,
                loading: true, isShowMessage: false, contacts: []
            }

        case CRAWLER_LEAD_SUCCESS:
            if (apiCall === CRAWLER_LEAD_LIST) {
                state.contacts = result;
                state.pagination.current = result.page;
                state.pagination.total = result.totalResults;
                state.pagination.pageSize = result.limit;
            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case CRAWLER_LEAD_ERROR:

            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case CRAWLER_LEAD_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }

        default:
            return state;
    }
}

export default CrawlerLead;