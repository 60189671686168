import { INBOX_ERROR, INBOX_GET, INBOX_HIDE_MESSAGE, INBOX_RESET, INBOX_SALES_GET, INBOX_SET_QUERY, INBOX_SET_QUERY_SALES, INBOX_SET_TYPE, INBOX_SUCCESS } from "redux/constants/Inbox";
const initialState = {
    success: false,
    loading: false,
    linkedin: { items: [], perPage: 0, totalCount: 0 },
    salesNavigator: { items: [], perPage: 0, totalCount: 0 },
    type: "linkedin",
    reset: false,
    resetSales: false,
    refreshId: 0,
    salesQuery: {
    },
    query: {
    },
    salesInActive: false
}

export const Inbox = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {

        case INBOX_RESET:
            return JSON.parse(JSON.stringify(initialState));
        case INBOX_GET:

            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case INBOX_SET_QUERY_SALES:
            state.resetSales = action.data.reset;
            state.salesQuery = { ...state.salesQuery, ...action.data.query };
            return {
                ...state,
            }
        case INBOX_SET_QUERY:
            state.reset = action.data.reset;
            state.query = { ...state.query, ...action.data.query };
            return {
                ...state
            }

        case INBOX_SALES_GET:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case INBOX_SUCCESS:

            if (apiCall === INBOX_GET) {
                state.linkedin.perPage = result.perPage;
                state.linkedin.totalCount = result.totalCount;
                let items = result.items;
                if (items) {
                    items = items.filter(item => item.participant.memberId && item.messages.length > 0);
                    items = items.map(item => {
                        item.messages[0].message = item.messages[0].message.slice(0, 100) + " ...";
                        return item;
                    })
                }
                result.items = items;

                if ((state.query.filters && state.query.filters != "CONNECTION") || state.reset)
                    state.linkedin.items = result.items;
                else
                    state.linkedin.items = state.linkedin.items.concat(result.items);
            }
            if (apiCall === INBOX_SALES_GET) {
                state.salesNavigator.perPage = result.perPage;
                state.salesNavigator.totalCount = result.totalCount;
                let items = result.items;
                if (items) {
                    items = items.filter(item => item.participant?.memberId && item.messages.length > 0);
                    items = items.map(item => {
                        item.messages[0].message = item.messages[0].message.slice(0, 100) + " ...";
                        return item;
                    })
                }
                result.items = items;

                if ((state.salesQuery.filters && state.salesQuery.filters != "INBOX") || state.resetSales)
                    state.salesNavigator.items = result.items;
                else
                    state.salesNavigator.items = state.salesNavigator.items.concat(result.items);

                if (result.message == "SALES_NAVIGATOR_NOT_EXIST")
                    state.salesInActive = true;
            }

            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case INBOX_ERROR:
            if (apiCall === INBOX_SALES_GET) {

                if (message == "SALES_NAVIGATOR_NOT_EXIST")
                    state.salesInActive = true;
            }

            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case INBOX_SET_TYPE:
            return {
                ...state,
                type: action.data

            }
        case INBOX_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }
        default:
            return state;
    }
}

export default Inbox;