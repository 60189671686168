
import {
    CONTACT_LIST, CONTACT_GET, CONTACT_ERROR, CONTACT_DELETE,
    CONTACT_HIDE_MESSAGE, CONTACT_SUCCESS, CONTACT_UPDATE, CONTACT_SET_QUERY, CONTACT_INIT, CONTACT_GET_LINKEDIN_CHAT, CONTACT_SEND_LINKEDIN_CHAT, CONTACT_ADD_NOTE, CONTACT_DELETE_NOTE, CONTACT_ADD_TAG, CONTACT_DELETE_TAG, CONTACT_RESET, CONTACT_CSV_DOWNLOAD
} from "redux/constants/Contacts";
export function contactGet(data) {
    return {
        type: CONTACT_GET,
        data
    }
}
export function contactList(data) {
    return {
        type: CONTACT_LIST,
        data
    }
}
export function contactCSVDownload(data) {
    return {
        type: CONTACT_CSV_DOWNLOAD,
        data
    }
}
export function contactGetLinkedinChat(id, accountId, salesInbox = false) {
    return {
        type: CONTACT_GET_LINKEDIN_CHAT,
        id, accountId, salesInbox
    }
}
export function contactSendLinkedinChat(id, accountId, data, salesInbox = false) {
    return {
        type: CONTACT_SEND_LINKEDIN_CHAT,
        data, id, accountId, salesInbox
    }
}
export function contactAddNote(id, contactId, data) {
    return {
        type: CONTACT_ADD_NOTE,
        data,
        id, contactId,
    }
}
export function contactDeleteNote(id, contactId, data) {
    return {
        type: CONTACT_DELETE_NOTE,
        id, data, contactId,
    }
}
export function contactAddTag(id, contactId, data) {
    return {
        type: CONTACT_ADD_TAG,
        data,
        id, contactId
    }
}
export function contactDeleteTag(id, contactId, data) {
    return {
        type: CONTACT_DELETE_TAG,
        id, data, contactId
    }
}
export function contactSetQuery(data) {
    return {
        type: CONTACT_SET_QUERY,
        data
    }
}
export function contactSuccess(data) {
    return {
        type: CONTACT_SUCCESS,
        data,
    }
}
export function contactHideMessage() {
    return {
        type: CONTACT_HIDE_MESSAGE,
    }
}
export function contactError(data) {
    return {
        type: CONTACT_ERROR,
        data

    }
}
export function contactInit(data) {
    return {
        type: CONTACT_INIT,
        data

    }
}
export function contactReset() {
    return {
        type: CONTACT_RESET,
    }
}
export function contactUpdate(id, contactId, data) {
    return {
        type: CONTACT_UPDATE,
        data,
        id, contactId
    }
}
export function contactDelete(id) {
    return {
        type: CONTACT_DELETE,
        id
    }
}