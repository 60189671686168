
import { CAMPAIGN_LIST, CAMPAIGN_RESET, CAMPAIGN_INIT, CAMPAIGN_GET, CAMPAIGN_SUCCESS, CAMPAIGN_DELETE, CAMPAIGN_HIDE_MESSAGE, CAMPAIGN_ERROR, CAMPAIGN_ADD_LEAD_SOURCE, CAMPAIGN_SET_STATE, CAMPAIGN_UPDATE, CAMPAIGN_SOURCE_TEST, CAMPAIGN_ARCHIVE, CAMPAIGN_DUPLICATE, CAMPAIGN_SAVE_AS_TEMPLATE, CAMPAIGN_SET_QUERY } from "redux/constants/Campaign";
import { merge } from "lodash";

const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    campaigns: {},
    updateCaller: "",
    statisticsType: "STATISTICS",
    currentCampaign: {},
    currentStep: 0,
    currentCampaignLastState: {},
    sourceAdded: false,
    refreshId: 0,
    pagination: {
        current: 1,
        pageSize: 10,
    },
    query: {
        sortBy: "createdAt",
        sortType: "descend",
        type: "linkedinCampaign",
    }
}
export const Campaign = (state = JSON.parse(JSON.stringify(initialState)), action) => {
    let currentCampaign;
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case CAMPAIGN_RESET:
            return JSON.parse(JSON.stringify(initialState));
        case CAMPAIGN_INIT:
            return merge(JSON.parse(JSON.stringify(initialState)), action.data)

        case CAMPAIGN_SOURCE_TEST:
            state.wizardState.searchUrl = action.data.url;
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CAMPAIGN_LIST:
            return {
                ...state,
                loading: true, isShowMessage: false, campaigns: []
            }
        case CAMPAIGN_GET:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CAMPAIGN_UPDATE:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case CAMPAIGN_SET_QUERY:
            return {
                ...state,
                query: { ...state.query, ...action.data }
            }

        case CAMPAIGN_DELETE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CAMPAIGN_ARCHIVE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CAMPAIGN_DUPLICATE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CAMPAIGN_SAVE_AS_TEMPLATE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CAMPAIGN_ADD_LEAD_SOURCE:
            return {
                ...state,
                loading: true, isShowMessage: false
            }
        case CAMPAIGN_SUCCESS:
            if (apiCall === CAMPAIGN_LIST) {
                state.campaigns = result;
                state.pagination.current = result.page;
                state.pagination.total = result.totalResults;
                state.pagination.pageSize = result.limit;
            }
            else if (apiCall === CAMPAIGN_GET)
                state.currentCampaign = result;
            else if (apiCall === CAMPAIGN_UPDATE) {
                if (state.currentCampaign.id)
                    state.currentCampaign = { ...state.currentCampaign, ...result };
                state.refreshId = Math.floor(Math.random() * 1000);
            } else if (apiCall === CAMPAIGN_ADD_LEAD_SOURCE) {
                state.currentCampaign.sources.push(result);
                state.currentCampaign.sourceAdded = true;
            }
            else if (apiCall === CAMPAIGN_SOURCE_TEST) {
                state.wizardState.testResult = result;
                state.wizardState.apiUrl = result.apiUrl;
                state.wizardState.maximumAmountResult = result.totalCount;
            }
            else if (apiCall === CAMPAIGN_DELETE) {
                state.currentCampaign = {};
                state.refreshId = Math.floor(Math.random() * 1000);
            }
            else if (apiCall === CAMPAIGN_SAVE_AS_TEMPLATE) {

            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case CAMPAIGN_ERROR:
            if (apiCall === CAMPAIGN_SOURCE_TEST)
                delete state.wizardState.testResult;
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case CAMPAIGN_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: false,
                message: "",
                isShowMessage: false
            }
        case CAMPAIGN_SET_STATE:
            let temp;
            if (action.data.statisticsType)
                temp = { statisticsType: action.data.statisticsType };
            else
                temp = { currentCampaign: { ...state.currentCampaign, ...action.data } };
            return {
                ...state,
                ...temp
            }
        default:
            return state;
    }
}

export default Campaign;