export const LINKEDIN_CAMPAIGN_SET_STEP = "LINKEDIN_CAMPAIGN_SET_STEP";
export const LINKEDIN_CAMPAIGN_SET_WIZARD_STATE = "LINKEDIN_CAMPAIGN_SET_WIZARD_STATE";
export const LINKEDIN_CAMPAIGN_SOURCE_TEST = "LINKEDIN_CAMPAIGN_SOURCE_TEST";
export const LINKEDIN_CAMPAIGN_HIDE_MESSAGE = "LINKEDIN_CAMPAIGN_HIDE_MESSAGE";
export const LINKEDIN_CAMPAIGN_ADD = "LINKEDIN_CAMPAIGN_ADD";
export const LINKEDIN_CAMPAIGN_UPDATE_DRAFT = "LINKEDIN_CAMPAIGN_UPDATE_DRAFT";
export const LINKEDIN_CAMPAIGN_SET_WIZARD = "LINKEDIN_CAMPAIGN_SET_WIZARD";
export const LINKEDIN_CAMPAIGN_SET_SOURCE = "LINKEDIN_CAMPAIGN_SET_SOURCE";
export const LINKEDIN_CAMPAIGN_SUCCESS = "LINKEDIN_CAMPAIGN_SUCCESS";
export const LINKEDIN_CAMPAIGN_ERROR = "LINKEDIN_CAMPAIGN_ERROR";
export const LINKEDIN_CAMPAIGN_SET_SEQUENCE = "LINKEDIN_CAMPAIGN_SET_SEQUENCE";
export const LINKEDIN_CAMPAIGN_REMOVE_SEQUENCE = "LINKEDIN_CAMPAIGN_REMOVE_SEQUENCE";
export const LINKEDIN_CAMPAIGN_SET_SEQUENCE_DATA = "LINKEDIN_CAMPAIGN_SET_SEQUENCE_DATA";
export const LINKEDIN_CAMPAIGN_INIT = "LINKEDIN_CAMPAIGN_INIT";
export const LINKEDIN_CAMPAIGN_ADD_LEADS = "LINKEDIN_CAMPAIGN_ADD_LEADS";
export const LINKEDIN_CAMPAIGN_RESET = "LINKEDIN_CAMPAIGN_RESET";
export const LINKEDIN_CAMPAIGN_REMOVE_ADDED_SOURCE = "LINKEDIN_CAMPAIGN_REMOVE_ADDED_SOURCE";
