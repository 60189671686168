import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import { useSelector } from "react-redux";
import { accountOptionsGet } from "redux/actions/System";
import { Spin } from 'antd';

import './style.css';
const LiAccounts = ({ match, accountOptions, accountOptionsGet }) => {
    const userData = useSelector(state => state.user.userData);
    useEffect(() => {
        if (accountOptions === undefined)
            accountOptionsGet();
    }, [])
    if (accountOptions && userData.role == "manager")
        return (
            <Suspense fallback={<Loading cover="content" />}>
                <Switch>
                    <Route path={`${match.url}/new`} component={lazy(() => import(`./new/`))} />
                    <Route path={`${match.url}/:id/verification`} component={lazy(() => import(`./verification/verificationForm`))} />
                    <Route path={`${match.url}/:id`} component={lazy(() => import(`./account/`))} />
                    <Route path={`${match.url}/`} component={lazy(() => import(`./list/`))} />
                    <Redirect from={`${match.url}`} to={`${match.url}/`} />
                </Switch>
            </Suspense>
        )
    else if (accountOptions && userData.role == "client")
        return (
            <Suspense fallback={<Loading cover="content" />}>
                <Switch>
                    <Route path={`${match.url}/automation/contacts`} component={lazy(() => import(`./account/contact`))} />
                    <Route path={`${match.url}/automation/inbox`} component={lazy(() => import(`./account/inbox`))} />
                    <Route path={`${match.url}/automation/activities`} component={lazy(() => import(`./account/activities`))} />
                    <Route path={`${match.url}/settings/notifications`} component={lazy(() => import(`./account/notifications`))} />
                    <Route path={`${match.url}/settings/bots`} component={lazy(() => import(`./account/settings/bots`))} />

                    <Route path={`${match.url}/:id`} component={lazy(() => import(`./account/`))} />

                    <Route path={`${match.url}/`} component={lazy(() => import(`./account/`))} />

                    <Redirect from={`${match.url}`} to={`${match.url}/`} />
                </Switch>
            </Suspense>
        )
    else {
        return (
            <div className="w-100 m-4"> <Spin size="medium" /></div>
        )
    }
};
const mapStateToProps = ({ system }) => {

    const { accountOptions } = system;
    return { accountOptions };
};
export default connect(mapStateToProps, { accountOptionsGet })(LiAccounts);