import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { linkedinCampaignError, linkedinCampaignSuccess } from "redux/actions";
import {
    LINKEDIN_CAMPAIGN_SET_STEP, LINKEDIN_CAMPAIGN_SET_WIZARD_STATE, LINKEDIN_CAMPAIGN_SUCCESS,
    LINKEDIN_CAMPAIGN_SOURCE_TEST, LINKEDIN_CAMPAIGN_ADD, LINKEDIN_CAMPAIGN_HIDE_MESSAGE, LINKEDIN_CAMPAIGN_SET_WIZARD, LINKEDIN_CAMPAIGN_SET_SOURCE, LINKEDIN_CAMPAIGN_UPDATE_DRAFT
} from "redux/constants/LinkedinCampaign";
import Service from 'services/CampaignService';
export function* testSearch() {
    yield takeLatest(LINKEDIN_CAMPAIGN_SOURCE_TEST, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.testSearch, params.data);
            yield put(linkedinCampaignSuccess({ result, success: true, isShowMessage: false, apiCall: LINKEDIN_CAMPAIGN_SOURCE_TEST }));
        } catch (err) {
            yield put(linkedinCampaignError({ isShowMessage: true, success: false, message: err.message, apiCall: LINKEDIN_CAMPAIGN_SOURCE_TEST }))
        }
    })

}


export function* linkedinCampaignAdd() {
    yield takeLatest(LINKEDIN_CAMPAIGN_ADD, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.linkedinCampaignAdd, params.data);
            yield put(linkedinCampaignSuccess({ result, success: true, isShowMessage: false, apiCall: LINKEDIN_CAMPAIGN_ADD }));
        } catch (err) {
            yield put(linkedinCampaignError({ isShowMessage: true, success: false, message: err.message, apiCall: LINKEDIN_CAMPAIGN_ADD }))
        }
    })

}

export function* linkedinCampaignUpdateDraft() {
    yield takeLatest(LINKEDIN_CAMPAIGN_UPDATE_DRAFT, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.linkedinCampaignUpdateDraft, params.data);
            yield put(linkedinCampaignSuccess({ result, success: true, isShowMessage: false, apiCall: LINKEDIN_CAMPAIGN_UPDATE_DRAFT }));
        } catch (err) {
            yield put(linkedinCampaignError({ isShowMessage: true, success: false, message: err.message, apiCall: LINKEDIN_CAMPAIGN_UPDATE_DRAFT }))
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(testSearch),
        fork(linkedinCampaignAdd),
        fork(linkedinCampaignUpdateDraft),

    ]);
}