import { NEW_CRAWLER, NEW_CRAWLER_ADD, NEW_CRAWLER_ERROR, NEW_CRAWLER_HIDE_MESSAGE, NEW_CRAWLER_INIT, NEW_CRAWLER_RESET, NEW_CRAWLER_SET_SOURCE, NEW_CRAWLER_SET_STEP, NEW_CRAWLER_SET_WIZARD, NEW_CRAWLER_SET_WIZARD_STATE, NEW_CRAWLER_SOURCE_TEST, NEW_CRAWLER_SUCCESS } from "redux/constants/Crawler";

export function newCrawler(data) {
    return {
        type: NEW_CRAWLER,
        data
    }
}
export function newCrawlerSetStep(data) {
    return {
        type: NEW_CRAWLER_SET_STEP,
        data
    }
}
export function newCrawlerSetWizardState(data) {
    return {
        type: NEW_CRAWLER_SET_WIZARD_STATE,
        data
    }
}
export function newCrawlerSetWizard(data) {
    return {
        type: NEW_CRAWLER_SET_WIZARD,
        data
    }
}
export function newCrawlerInit(data) {
    return {
        type: NEW_CRAWLER_INIT,
        data
    }
}
export function newCrawlerReset() {
    return {
        type: NEW_CRAWLER_RESET,

    }
}
export function newCrawlerSuccess(data) {
    return {
        type: NEW_CRAWLER_SUCCESS,
        data
    }
}
export function newCrawlerError(data) {
    return {
        type: NEW_CRAWLER_ERROR,
        data
    }
}
export function newCrawlerHideMessage() {
    return {
        type: NEW_CRAWLER_HIDE_MESSAGE,

    }
}

export function newCrawlerSetSource(data) {
    return {
        type: NEW_CRAWLER_SET_SOURCE,
        data
    }
}
export function newCrawlerSourceTest(data) {
    return {
        type: NEW_CRAWLER_SOURCE_TEST,
        data
    }
}


export function newCrawlerAdd(data) {
    return {
        type: NEW_CRAWLER_ADD,
        data
    }
}