import { BILLINGS_ERROR, BILLINGS_HIDE_MESSAGE, BILLINGS_SHOW_MESSAGE, BILLINGS_SUCCESS, DELETE_LICENSE, GET_INVOICES, GET_LICENSES, GET_LICENSES_STATS, PURCHASE_LICENSES } from "redux/constants/Billings";

const initialState = {
    success: undefined,
    loading: false,
    message: "",

    isShowMessage: false,

    pagination: {
        current: 1,
        pageSize: 50,
    },
    query: {
        sortBy: "createdAt",
        sortType: "descend",
    },
    invoices: [],
    stats: {},
    results: []
}

export const Billings = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case GET_LICENSES:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case GET_LICENSES_STATS:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case GET_INVOICES:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case DELETE_LICENSE:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case BILLINGS_SUCCESS:
            if (apiCall === GET_LICENSES)
                state.results = result?.results || [];
            if (apiCall === GET_INVOICES) {
                state.invoices = state.invoices.concat(result?.data || []);
                state.hasMore = result?.has_more;
                state.nextPage = result?.next_page;
            }
            else if (apiCall == GET_LICENSES_STATS)
                state.stats = result || {};
            else if (apiCall == DELETE_LICENSE) {
                state.results = state.results.map(item => {
                    if (item.id == result.id)
                        item.status = "deactivated"
                    return item;
                });
            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case BILLINGS_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        case BILLINGS_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: undefined,
                message: "",
                isShowMessage: false
            }
        case BILLINGS_SHOW_MESSAGE:
            return {
                ...state,
                loading: false,
                success: undefined,
                message: "",
                isShowMessage: true
            }
        case PURCHASE_LICENSES:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        default:
            return state;
    }
}

export default Billings;