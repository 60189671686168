export const GET_LIACCOUNT = "GET_LIACCOUNT";

export const UPDATE_LIACCOUNT = "UPDATE_LIACCOUNT";
export const UPDATE_LIACCOUNT_PASSWORD = "UPDATE_LIACCOUNT_PASSWORD";
export const LI_ACCOUNT_SHOW_MESSAGE = "LI_ACCOUNT_SHOW_MESSAGE";
export const LIACCOUNT_HIDE_MESSAGE = "LIACCOUNT_HIDE_MESSAGE";
export const LIACCOUNT_VERIFY = "LIACCOUNT_VERIFY";
export const LIACCOUNT_RESEND_CODE = "LIACCOUNT_RESEND_CODE";


export const AUTOMATION_MENU_SETCURRENT = "AUTOMATION_MENU_SETCURRENT";
export const PROFILE_MENU_SETCURRENT = "PROFILE_MENU_SETCURRENT";
export const SETTINGS_MENU_SETCURRENT = "SETTINGS_MENU_SETCURRENT";

export const LIACCOUNT_SUCCESS = "LIACCOUNT_SUCCESS";
export const LIACCOUNT_ERROR = "LIACCOUNT_ERROR";
export const LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS = "LIACCOUNT_UPDATE_NOTIFICATION_SETTINGS";
export const LIACCOUNT_SEND_INVITE = "LIACCOUNT_SEND_INVITE";
export const LIACCOUNT_LIST = "LIACCOUNT_LIST";

