export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS";
export const GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const SUBSCRIBE_TO_PLAN = "SUBSCRIBE_TO_SUBSCRIPTION";
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS";
export const SUBSCRIPTION_ERROR = "SUBSCRIPTION_ERROR";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const SUBSCRIPTION_SWITCH = "SUBSCRIPTION_SWITCH";
export const SUBSCRIPTION_CANCEL = "SUBSCRIPTION_CANCEL";
export const SUBSCRIPTION_HIDE_MESSAGE = "SUBSCRIPTION_HIDE_MESSAGE";

export const STRIPE_GET_SUBSCRIPTION_PLANS = "STRIPE_GET_SUBSCRIPTION_PLANS";
export const STRIPE_GET_SUBSCRIPTION_PLAN = "STRIPE_GET_SUBSCRIPTION_PLAN";

export const STRIPE_SUBSCRIPTION_UPDATE = "STRIPE_SUBSCRIPTION_UPDATE";
export const STRIPE_SUBSCRIBE_TO_PLAN = "STRIPE_SUBSCRIBE_TO_PLAN";
export const STRIPE_SUBSCRIPTION_SUCCESS = "STRIPE_SUBSCRIPTION_SUCCESS";
export const STRIPE_SUBSCRIPTION_ERROR = "STRIPE_SUBSCRIPTION_ERROR";
export const STRIPE_SUBSCRIPTION_SWITCH = "STRIPE_SUBSCRIPTION_SWITCH";
export const STRIPE_SUBSCRIPTION_CANCEL = "STRIPE_SUBSCRIPTION_CANCEL";
export const STRIPE_SUBSCRIPTION_CREATE = "STRIPE_SUBSCRIPTION_CREATE";
export const STRIPE_SUBSCRIPTION_RESET_STATE = "STRIPE_SUBSCRIPTION_RESET_STATE";
export const STRIPE_SUBSCRIPTION_HIDE_MESSAGE = "STRIPE_SUBSCRIPTION_HIDE_MESSAGE";


