import { GET_POST_ALERTS, GET_POST_ALERTS_LEADS, GET_POST_ALERTS_PROFILE_URLS, POST_ALERTS_ERROR, POST_ALERTS_HIDE_MESSAGE, POST_ALERTS_LEAD_ERROR, POST_ALERTS_LEAD_HIDE_MESSAGE, POST_ALERTS_LEAD_INIT, POST_ALERTS_LEAD_RESET, POST_ALERTS_LEAD_SUCCESS, POST_ALERTS_SHOW_MESSAGE, POST_ALERTS_SUCCESS, UPLOAD_POST_ALERTS_CSV } from "redux/constants/PostAlerts";

export function getPostAlerts(data) {
    return {
        type: GET_POST_ALERTS,
        data
    }
}
export function uploadPostAlertsCSV(data) {
    return {
        type: UPLOAD_POST_ALERTS_CSV,
        data
    }
}
export function getPostAlertsLeads(data) {
    return {
        type: GET_POST_ALERTS_LEADS,
        data
    }
}
export function getPostAlertsProfileUrls(data) {
    return {
        type: GET_POST_ALERTS_PROFILE_URLS,
        data
    }
}

export function postAlertsSuccess(data) {
    return {
        type: POST_ALERTS_SUCCESS,
        data
    }
}
export function postAlertsError(data) {
    return {
        type: POST_ALERTS_ERROR,
        data
    }
}
export function postAlertsHideMessage() {
    return {
        type: POST_ALERTS_HIDE_MESSAGE,
    }
}

export function postAlertsShowMessage() {
    return {
        type: POST_ALERTS_SHOW_MESSAGE,
    }
}



export function postAlertsGetLeadsError(data) {
    return {
        type: POST_ALERTS_LEAD_ERROR,
        data

    }
}

export function postAlertsGetLeadsSuccess(data) {
    return {
        type: POST_ALERTS_LEAD_SUCCESS,
        data,
    }
}
export function postAlertsGetLeadsHideMessage() {
    return {
        type: POST_ALERTS_LEAD_HIDE_MESSAGE,
    }
}

export function postAlertsGetLeadsInit() {
    return {
        type: POST_ALERTS_LEAD_INIT,
    }
}

export function postAlertsGetLeadsReset() {
    return {
        type: POST_ALERTS_LEAD_RESET,
    }
}