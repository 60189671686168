import fetch from 'auth/FetchInterceptor'
const DataService = {};

DataService.messageTemplateNew = function (data) {
    return fetch({
        url: `/messageTemplate/`,
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data: cleanlinkedinMessageTemplateData(data)
    })
}

DataService.messageTemplateList = function (options) {
    var queryString = Object.keys(options).filter(
        key => options[key]
    ).map(
        key => {
            if (options[key])
                return key + '=' + options[key]
            else
                return "";
        }
    ).join("&");

    return fetch({
        url: `/messageTemplate?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })

}
DataService.messageTemplateGet = function (data) {

    return fetch({
        url: '/messageTemplate/' + data.id,
        method: 'get',
        headers: {
            'public-request': "true"
        },
        data
    })
}
DataService.messageTemplateDelete = function (data) {

    return fetch({
        url: '/messageTemplate/' + data,
        method: 'delete',
        headers: {
            'public-request': "true"
        },
        data
    })
}
DataService.messageTemplateUpdate = function (id, data) {

    return fetch({
        url: '/messageTemplate/' + id,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}
function cleanlinkedinMessageTemplateData(data) {
    const copy = { ...data, ...{} };

    return copy;
}


export default DataService;