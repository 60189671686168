import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import user from './User';
import liAccount, { LiAccountMenu } from './LiAccount';
import subscription from './Subscription';
import quickSearch from './QuickSearch';
import crawler from './Crawler';
import linkedinCampaign from './LinkedinCampaign';
import campaign from './Campaign';
import system from './System';
import contact from './Contact';
import messageTemplate from './MessageTemplate';
import inbox from './Inbox';
import crawlerLead from './SearchLead';
import newCrawler from './NewCrawler';
import subscriptionStripe from './SubscriptionStripe';
import postAlerts from './PostAlerts';
import postAlertsLead from './PostAlertsLead';
import billings from './Billings';
const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    user,
    liAccount, inbox, newCrawler,
    liAccountMenu: LiAccountMenu,
    subscription, postAlerts,
    quickSearch, crawlerLead, postAlertsLead,
    subscriptionStripe,
    crawler, linkedinCampaign, campaign, system, contact, messageTemplate, billings
});

export default reducers;