import { GET_POST_ALERTS, POST_ALERTS_ERROR, POST_ALERTS_HIDE_MESSAGE, POST_ALERTS_SHOW_MESSAGE, POST_ALERTS_SUCCESS, UPLOAD_POST_ALERTS_CSV } from "redux/constants/PostAlerts";

const initialState = {
    success: undefined,
    loading: false,
    message: "",

    isShowMessage: false,

    pagination: {
        current: 1,
        pageSize: 10,
    },
    query: {
        sortBy: "createdAt",
        sortType: "descend",
    },
    results: []
}

export const PostAlerts = (state = initialState, action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case GET_POST_ALERTS:
            return {
                ...state,
                loading: true, isShowMessage: false,
            }
        case UPLOAD_POST_ALERTS_CSV:
            return {
                ...state, success: undefined,
                loading: true, isShowMessage: false,
            }
        case POST_ALERTS_SUCCESS:
            if (apiCall === GET_POST_ALERTS)
                state.results = result;

            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case POST_ALERTS_ERROR:
            return {
                ...state,
                loading: false,
                success: undefined,
                message: message,
                isShowMessage: isShowMessage
            }
        case POST_ALERTS_HIDE_MESSAGE:
            return {
                ...state,
                loading: false,
                success: undefined,
                message: "",
                isShowMessage: false
            }
        case POST_ALERTS_SHOW_MESSAGE:
            return {
                ...state,
                loading: false,
                success: undefined,
                message: "",
                isShowMessage: false
            }
        default:
            return state;
    }
}

export default PostAlerts;