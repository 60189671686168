export const SOURCE_TYPE_QUICK_SEARCH = "SOURCE_TYPE_QUICK_SEARCH";
export const SOURCE_TYPE_QUICK_SEARCH_SALES = "SOURCE_TYPE_QUICK_SEARCH_SALES";
export const SOURCE_TYPE_1ST_DEGREE = "SOURCE_TYPE_1ST_DEGREE";
export const SOURCE_TYPE_CUSTOM_SEARCH = "SOURCE_TYPE_CUSTOM_SEARCH";
export const SOURCE_TYPE_CUSTOM_SEARCH_SALES = "SOURCE_TYPE_CUSTOM_SEARCH_SALES";
export const SOURCE_TYPE_CSV_UPLOAD = "SOURCE_TYPE_CSV_UPLOAD";
export const SOURCE_TYPE_CRAWLER = "SOURCE_TYPE_CRAWLER";
export const SOURCE_TYPE_NONE = "SOURCE_TYPE_NONE";
export const MESSAGING_TYPE_CONNECTOR = "CONNECTOR";
export const MESSAGING_TYPE_MESSENGER = "MESSENGER";
export const MESSAGING_TYPES = [MESSAGING_TYPE_CONNECTOR, MESSAGING_TYPE_MESSENGER];
export const MENUITEM_ENGAGE_WITH_PROFILE = "MENUITEM_ENGAGE_WITH_PROFILE";
export const MENUITEM_SEND_INVITE = "MENUITEM_SEND_INVITE";
export const MENUITEM_SEND_LINKEDIN_MESSAGE = "MENUITEM_SEND_LINKEDIN_MESSAGE";
export const MENUITEM_SEND_INMAIL = "MENUITEM_SEND_INMAIL";
export const PERSONALIZE_ITEM_FIRSTNAME = "{firstName}";
export const PERSONALIZE_ITEM_LASTNAME = "{lastName}";
export const PERSONALIZE_ITEM_POSITION = "{position}";
export const PERSONALIZE_ITEM_COMPANY = "{company}";
export const PERSONALIZE_ITEM_INDUSTRY = "{industry}";
export const PERSONALIZE_ITEM_LOCATION = "{location}";

export const SOURCE_TYPE_PROFILE_VIEWS = "SOURCE_TYPE_PROFILE_VIEWS";

