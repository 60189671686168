import { BILLINGS_ERROR, BILLINGS_HIDE_MESSAGE, BILLINGS_INIT, BILLINGS_RESET, BILLINGS_SHOW_MESSAGE, BILLINGS_SUCCESS, DELETE_LICENSE, GET_INVOICES, GET_LICENSES, GET_LICENSES_STATS, PURCHASE_LICENSES } from "redux/constants/Billings";

export function getLicenses(data) {
    return {
        type: GET_LICENSES,
        data
    }
}
export function getLicensesStats(data) {
    return {
        type: GET_LICENSES_STATS,
        data
    }
}
export function purchaseLicenses(data) {
    return {
        type: PURCHASE_LICENSES,
        data
    }
}
export function billingsSuccess(data) {
    return {
        type: BILLINGS_SUCCESS,
        data
    }
}
export function billingsError(data) {
    return {
        type: BILLINGS_ERROR,
        data
    }
}
export function billingsHideMessage(data) {
    return {
        type: BILLINGS_HIDE_MESSAGE,
        data
    }
}
export function billingsShowMessage(data) {
    return {
        type: BILLINGS_SHOW_MESSAGE,
        data
    }
}

export function billingsInit(data) {
    return {
        type: BILLINGS_INIT,
        data
    }
}
export function billingsReset(data) {
    return {
        type: BILLINGS_RESET,
        data
    }
}

export function deleteLicense(data) {
    return {
        type: DELETE_LICENSE,
        data
    }
}
export function getInvoices(data) {
    return {
        type: GET_INVOICES,
        data
    }
}