
export const CONTACT_LIST = "CONTACT_LIST";
export const CONTACT_CSV_DOWNLOAD = "CONTACT_CSV_DOWNLOAD";
export const CONTACT_GET = "CONTACT_GET";
export const CONTACT_ERROR = "CONTACT_ERROR";
export const CONTACT_RESET = "CONTACT_RESET";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_HIDE_MESSAGE = "CONTACT_HIDE_MESSAGE";
export const CONTACT_SET_STATE = "CONTACT_SET_STATE";
export const CONTACT_UPDATE = "CONTACT_UPDATE";
export const CONTACT_DELETE = "CONTACT_DELETE";
export const CONTACT_SET_QUERY = "CONTACT_SET_QUERY";
export const CONTACT_INIT = "CONTACT_INIT";
export const CONTACT_DELETE_NOTE = "CONTACT_DELETE_NOTE";
export const CONTACT_ADD_NOTE = "CONTACT_ADD_NOTE";
export const CONTACT_GET_LINKEDIN_CHAT = "CONTACT_GET_LINKEDIN_CHAT";
export const CONTACT_SEND_LINKEDIN_CHAT = "CONTACT_SEND_LINKEDIN_CHAT";
export const CONTACT_DELETE_TAG = "CONTACT_DELETE_TAG";
export const CONTACT_ADD_TAG = "CONTACT_ADD_TAG";



export const INVITE_STATUS_ACCEPTED = "accepted";
export const INVITE_STATUS_DECLINED = "declined";
export const INVITE_STATUS_SENT = "sent";
export const INVITE_STATUS_READY = "ready";
export const INVITE_STATUS_RESTRICTED = "invite_restricted";
export const INVITE_STATUS_ERROR = "error";
export const INVITE_STATUSES = [INVITE_STATUS_SENT, INVITE_STATUS_ACCEPTED, INVITE_STATUS_DECLINED, INVITE_STATUS_ERROR, INVITE_STATUS_READY, INVITE_STATUS_RESTRICTED];

export const CONTACT_STATUS_CONNECTION = "connection";
export const CONTACT_STATUS_LEAD = "lead";
export const CONTACT_STATUSES = [CONTACT_STATUS_CONNECTION, CONTACT_STATUS_LEAD];

export const CONN_STATUS_CONNECTED = "connected";
export const CONN_STATUS_INVITE_SENT = "invite_sent";
export const CONN_STATUS_DISCONNECTED = "disconnected";
export const CONN_STATUS_IMPORTED = "imported";
export const CONN_STATUS_MESSAGE = "message";
export const CONN_STATUS_IGNORED = "ignored";
export const CONN_STATUS_REPLIED = "replied";
export const CONN_STATUS_CHATTING = "chatting";
export const CONN_STATUS_FOLLOW_UP = "followup_sent";
export const CONN_STATUS_WELCOME_MESSAGE = "welcome_message";
export const CONN_STATUS_INVITE_ERROR = "invite_error";
export const CONN_STATUS_LINK_CLICKED = "link_clicked";
export const CONN_STATUS_WITHDRAWN = "withdrawn";
export const CONN_STATUS_MESSAGE_ERROR = "message_error";
export const CONN_STATUSES = [CONN_STATUS_CONNECTED, CONN_STATUS_INVITE_SENT, CONN_STATUS_DISCONNECTED,
    CONN_STATUS_IMPORTED, CONN_STATUS_MESSAGE, CONN_STATUS_IGNORED, CONN_STATUS_REPLIED,
    CONN_STATUS_WELCOME_MESSAGE, CONN_STATUS_INVITE_ERROR,
    CONN_STATUS_WITHDRAWN, CONN_STATUS_MESSAGE_ERROR];

