import { MESSAGING_TYPE_CONNECTOR, MESSAGING_TYPE_MESSENGER, SOURCE_TYPE_NONE, SOURCE_TYPE_QUICK_SEARCH_SALES } from "constants/MainConstant";
import { CampaignSequence, CAMPAIGN_SEQUENCE_SEND_INVITE } from "models/campaigns.model";
import { merge } from "lodash";
import {
    LINKEDIN_CAMPAIGN_SET_STEP, LINKEDIN_CAMPAIGN_SET_WIZARD_STATE, LINKEDIN_CAMPAIGN_SUCCESS, LINKEDIN_CAMPAIGN_INIT, LINKEDIN_CAMPAIGN_UPDATE_DRAFT,
    LINKEDIN_CAMPAIGN_SOURCE_TEST, LINKEDIN_CAMPAIGN_ADD, LINKEDIN_CAMPAIGN_REMOVE_SEQUENCE, LINKEDIN_CAMPAIGN_HIDE_MESSAGE, LINKEDIN_CAMPAIGN_SET_WIZARD, LINKEDIN_CAMPAIGN_SET_SOURCE, LINKEDIN_CAMPAIGN_ERROR, LINKEDIN_CAMPAIGN_SET_SEQUENCE, LINKEDIN_CAMPAIGN_SET_SEQUENCE_DATA, LINKEDIN_CAMPAIGN_RESET, LINKEDIN_CAMPAIGN_REMOVE_ADDED_SOURCE
} from "redux/constants/LinkedinCampaign";

const initialState = {
    success: false,
    loading: false,
    message: "",
    isShowMessage: false,
    currentStep: 0,
    sequence: [],
    rand: 0,
    mode: "create",
    wizardState: {
        selectedSource: SOURCE_TYPE_NONE,
        sequenceData: [],
        maximumAmountResult: 0,
        searchUrl: "",
        status: "draft",
        systemFilters: [],
        nameError: false,
        added: false,
        name: "New linkedin campaign",
        crawlerIds: [],
        csvFileName: "",
        inboxType: "sales_navigator",
        messagingType: MESSAGING_TYPE_MESSENGER
    }
}

export const LinkedinCampaign = (state = JSON.parse(JSON.stringify(initialState)), action) => {
    if (action.data) {
        var { result, message, isShowMessage, apiCall } = action.data;
    }
    switch (action.type) {
        case LINKEDIN_CAMPAIGN_RESET:
            return JSON.parse(JSON.stringify(initialState));
        case LINKEDIN_CAMPAIGN_INIT:
            return merge(JSON.parse(JSON.stringify(initialState)), action.data)
        case LINKEDIN_CAMPAIGN_ADD:
            state.wizardState.status = action.data.status;
            return {
                ...state,
                loading: true,
            }
        case LINKEDIN_CAMPAIGN_UPDATE_DRAFT:
            state.wizardState.status = action.data.status;
            return {
                ...state,
                loading: true,
            }
        case LINKEDIN_CAMPAIGN_REMOVE_ADDED_SOURCE:
            state.wizardState.crawlerIds = state.wizardState.crawlerIds.filter((item, index) => {
                return (action.data) != item;
            });
            return {
                ...state,
            }
        case LINKEDIN_CAMPAIGN_REMOVE_SEQUENCE:
            state.sequence = state.sequence.filter((item, index) => {
                if ((action.data) != index) {
                    return true;
                }
                else {
                    if (item.type == CAMPAIGN_SEQUENCE_SEND_INVITE)
                        state.wizardState.messagingType = MESSAGING_TYPE_MESSENGER;
                    return false;
                }

            });
            state.wizardState.sequenceData = state.wizardState.sequenceData.filter((item, index) => {
                return (action.data) != index;
            });
            return {
                ...state,
                // sequence: temp,
                rand: Math.floor(Math.random() * 1000)
            }
        case LINKEDIN_CAMPAIGN_SET_SEQUENCE:
            state.sequence.push(action.data);
            state.wizardState.sequenceData.push(new CampaignSequence(action.data));
            if (action.data.type == CAMPAIGN_SEQUENCE_SEND_INVITE) {
                state.wizardState.messagingType = MESSAGING_TYPE_CONNECTOR;
            }
            return {
                ...state,
                sequence: state.sequence,
                wizardState: state.wizardState,
                rand: Math.floor(Math.random() * 1000)
            }

        case LINKEDIN_CAMPAIGN_SET_STEP:
            return {
                ...state,
                currentStep: action.data
            }
        case LINKEDIN_CAMPAIGN_SET_WIZARD_STATE:
            const wizardState = { ...state.wizardState, ...action.data };
            return {
                ...state,
                rand: Math.floor(Math.random() * 1000),
                wizardState
            };

        case LINKEDIN_CAMPAIGN_SET_SEQUENCE_DATA:
            let stepData = state.wizardState.sequenceData[action.index];
            stepData = { ...stepData, ...action.data };
            state.wizardState.sequenceData[action.index] = stepData;
            if (action.data.forceRerender === false)
                return {
                    ...state,
                    wizardState: state.wizardState
                }
            else return {
                ...state,
                rand: Math.floor(Math.random() * 1000),
                wizardState: state.wizardState
            }
        case LINKEDIN_CAMPAIGN_SET_SOURCE:
            state.wizardState.maximumAmountResult = 1000;
            if (action.data.selectedSource == SOURCE_TYPE_QUICK_SEARCH_SALES)
                state.wizardState.maximumAmountResult = 2500;
            return {
                ...state,
                currentStep: action.data.currentStep,
                wizardState: { ...state.wizardState, selectedSource: action.data.selectedSource }
            };
        case LINKEDIN_CAMPAIGN_SOURCE_TEST:
            state.wizardState.searchUrl = action.data.url;
            return {
                ...state,
                loading: true
            }
        case LINKEDIN_CAMPAIGN_SUCCESS:
            if (apiCall === LINKEDIN_CAMPAIGN_SOURCE_TEST) {
                state.wizardState.testResult = result;
                state.wizardState.apiUrl = result.apiUrl;
                state.wizardState.maximumAmountResult = result.totalCount;
            }
            else if (apiCall === LINKEDIN_CAMPAIGN_ADD) {
                state.wizardState.added = true;
                state.wizardState.id = action.data.result.id;
            }
            return {
                ...state,
                loading: false,
                success: true,
                message: message,
                isShowMessage: isShowMessage
            }
        case LINKEDIN_CAMPAIGN_ERROR:
            if (apiCall === LINKEDIN_CAMPAIGN_SOURCE_TEST)
                delete state.wizardState.testResult;
            return {
                ...state,
                loading: false,
                success: false,
                message: message,
                isShowMessage: isShowMessage
            }
        default:
            return state;
    }
}
export default LinkedinCampaign;