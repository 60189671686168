import fetch from 'auth/FetchInterceptor'
import { SOURCE_TYPE_1ST_DEGREE, SOURCE_TYPE_CSV_UPLOAD } from 'constants/MainConstant';
const InboxService = {};
function bodyToQueryString(body) {
    return Object.keys(body).filter(
        key => body[key] !== undefined
    ).map(
        key => {
            if (body[key] !== undefined)
                return key + '=' + body[key]
            else
                return "";
        }
    ).join("&");
}

InboxService.getInbox = function (options) {
    const queryString = bodyToQueryString(options);
    if (options.type == "linkedin")
        var url = `/inbox/?${queryString}`;
    else
        var url = `/inbox/sales?${queryString}`;
    return fetch({
        url,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}



export default InboxService;