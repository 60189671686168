import fetch from 'auth/FetchInterceptor'
import { SOURCE_TYPE_1ST_DEGREE, SOURCE_TYPE_CSV_UPLOAD } from 'constants/MainConstant';

function bodyToQueryString(body) {
    return Object.keys(body).filter(
        key => body[key] !== undefined
    ).map(
        key => {
            if (body[key] !== undefined)
                return key + '=' + body[key]
            else
                return "";
        }
    ).join("&");
}

const CampaignService = {};
CampaignService.testSearch = function (data) {
    if (data.type == SOURCE_TYPE_1ST_DEGREE)
        return fetch({
            url: `/accounts/${data.accountId}/connections?advance=false`,
            method: 'get',
            headers: {
                'public-request': "true"
            },
            data
        })
    else
        return fetch({
            url: '/crawler/test',
            method: 'post',
            headers: {
                'public-request': "true"
            },
            data
        })
}
CampaignService.linkedinCampaignAdd = function (data) {
    let accountId = data.accountId;
    let url = `/campaign/?accountId=${accountId}`;
    if (data.status == "draft") {
        url = `/campaign/draft?accountId=${accountId}`;
    }
    if (data.id == undefined)
        delete data.id;
    if (data.selectedSource == SOURCE_TYPE_CSV_UPLOAD) {
        let temp = cleanlinkedinCampaignCSVData(data);

        let formData = new FormData()
        temp.searchUrl = "csv upload ";
        temp.systemFilters = [];
        temp.columns = temp.columns.map(item => item.dataIndex);
        Object.keys(temp).forEach(key => {
            if (key != "csvFile") {
                if (['sequenceData', 'systemFilters', 'columns', 'csvResult', 'wizardState'].indexOf(key) !== -1) {
                    if (key == "wizardState") {
                        delete temp[key].csvFile;
                        delete temp[key].csvResult;
                        delete temp[key].columns;
                    }
                    formData.append(key, JSON.stringify(temp[key]));
                }
                else
                    formData.append(key, temp[key]);
            }
            else {
                formData.append(key, data.csvFile, data.csvFile.name);
            }

        });

        return fetch({
            url,
            method: 'post',
            headers: {
                'public-request': "true",
                'content-type': 'multipart/form-data',
            },
            data: formData
        })
    }

    else
        return fetch({
            url,
            method: 'post',
            headers: {
                'public-request': "true"
            },
            data: cleanlinkedinCampaignData(data)
        })
}

CampaignService.campaignList = function (options) {
    const { pagination = {}, query } = options;
    delete options?.pagination;
    delete options?.query;

    var { current: page, pageSize: limit, sortBy, sortType } = pagination;
    options.projection = "-connections -memberIds";
    options = { ...options, ...query, page, limit, sortType, sortBy };

    var queryString = bodyToQueryString(options);

    return fetch({
        url: `/campaign?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })

}
CampaignService.campaignGet = function (data) {

    return fetch({
        url: '/campaign/' + data.id,
        method: 'get',
        headers: {
            'public-request': "true"
        },
        data
    })
}
CampaignService.campaignDelete = function (data) {

    return fetch({
        url: '/campaign/' + data,
        method: 'delete',
        headers: {
            'public-request': "true"
        },
        data
    })
}
CampaignService.campaignUpdate = function (id, data) {
    const accountId = data.accountId;
    delete data.accountId;
    return fetch({
        url: '/campaign/' + id + `?accountId=${accountId}`,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}
CampaignService.campaignSaveAsTemplate = function (id) {

    return fetch({
        url: '/campaign/' + id + "/saveAsTemplate",
        method: 'post',
        headers: {
            'public-request': "true"
        }
    })
}
CampaignService.campaignAdvanceUpdate = function (id, data) {
    return fetch({
        url: '/campaign/' + id,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}
CampaignService.addLeadsSource = function (id, data) {

    return fetch({
        url: '/campaign/' + id + "/addLeadsSource",
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}

CampaignService.getActivities = function (id, options) {
    var queryString = bodyToQueryString(options);
    return fetch({
        url: `/campaign/${id}/activities/?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },
    })
}
CampaignService.linkedinCampaignUpdateDraft = function (data) {
    const accountId = data.accountId;
    delete data.accountId;
    if (data.wizardState.testResult && data.wizardState.testResult.items.length > 10) {
        data.wizardState.testResult.items = data.wizardState.testResult.items.splice(1, 10);
    }
    return fetch({
        url: '/campaign/' + data.id + `/updateDraft?accountId=${accountId}`,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}

CampaignService.getLeads = function (id, options) {
    let queryString = bodyToQueryString(options);
    return fetch({
        url: `/campaign/${id}/leads?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}
CampaignService.downloadCSV = function (options) {
    let queryString = bodyToQueryString(options);
    return fetch({
        url: `/contact/csvDownload/?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}

function cleanlinkedinCampaignData(data) {
    const copy = { ...data, ...{} };
    delete copy.testResult;
    delete copy.csvResult;
    delete copy.nameError;
    delete copy.added;
    delete copy.accountId;
    return copy;
}
function cleanlinkedinCampaignCSVData(data) {
    const copy = { ...data, ...{} };
    delete copy.testResult;
    delete copy.nameError;
    delete copy.added;
    delete copy.csvFile;
    delete copy.accountId;
    return copy;
}

export default CampaignService;