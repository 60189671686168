import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { postAlertsError, postAlertsGetLeadsError, postAlertsGetLeadsSuccess, postAlertsSuccess } from 'redux/actions/PostAlerts';
import { GET_POST_ALERTS, GET_POST_ALERTS_LEADS, UPLOAD_POST_ALERTS_CSV } from 'redux/constants/PostAlerts';
import Service from 'services/PostAlerts.Service';

export function* getPostAlerts() {
    yield takeLatest(GET_POST_ALERTS, function* (params) {
        try {
            const result = yield call(Service.getPostAlerts, params.data);
            yield put(postAlertsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_POST_ALERTS }));
        } catch (err) {
            yield put(postAlertsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_POST_ALERTS }))
        }
    })

}
export function* uploadPostAlertsCSV() {
    yield takeLatest(UPLOAD_POST_ALERTS_CSV, function* (params) {
        try {
            var result;
            if (params.data.postAlertId)
                result = yield call(Service.addProfileUrls, params.data);
            else
                result = yield call(Service.create, params.data);
            yield put(postAlertsSuccess({ result, message: "Uploaded succesfully.", success: true, isShowMessage: true, apiCall: UPLOAD_POST_ALERTS_CSV }));
        } catch (err) {
            yield put(postAlertsError({ isShowMessage: true, success: false, message: err.message, apiCall: UPLOAD_POST_ALERTS_CSV }))
        }
    })

}

export function* postAlertsGetLeads() {
    yield takeLatest(GET_POST_ALERTS_LEADS, function* (params) {
        try {
            const result = yield call(Service.getLeads, params.data);
            yield put(postAlertsGetLeadsSuccess({ result, success: true, isShowMessage: false, apiCall: GET_POST_ALERTS_LEADS }));
        } catch (err) {
            yield put(postAlertsGetLeadsError({ isShowMessage: true, success: false, message: err.message, apiCall: GET_POST_ALERTS_LEADS }))
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(getPostAlerts),
        fork(uploadPostAlertsCSV),
        fork(postAlertsGetLeads),
    ]);
}