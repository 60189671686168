import fetch from 'auth/FetchInterceptor'
const ContactService = {};

function bodyToQueryString(body) {
    return Object.keys(body).filter(
        key => body[key] !== undefined
    ).map(
        key => {
            if (body[key] !== undefined)
                return key + '=' + body[key]
            else
                return "";
        }
    ).join("&");
}

ContactService.contactList = function (options) {

    if (options.campaignId) {
        delete options.contactStatus;
        delete options.status;
        let queryString = bodyToQueryString(options);
        return fetch({
            url: `/campaign/${options.campaignId}/leads?${queryString}`,
            method: 'get',
            headers: {
                'public-request': "true"
            }
        })
    }
    else {
        let queryString = bodyToQueryString(options);
        return fetch({
            url: `/contact?${queryString}`,
            method: 'get',
            headers: {
                'public-request': "true"
            }
        })
    }
}
ContactService.contactCSVDownload = function (options) {
    let queryString = bodyToQueryString(options);

    return fetch({
        url: `/contact/csvDownload?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })

}

ContactService.mapLatLangs = function (options) {
    const queryString = bodyToQueryString(options);
    return fetch({
        url: `/contact/mapLatLngs?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })

}
ContactService.contactGet = function (options) {
    const { id } = options;
    delete options.id;
    let queryString = bodyToQueryString(options);
    return fetch({
        url: `/contact/${id}?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}
ContactService.contactGetLinkedinChat = function (id, accountId, salesInbox) {

    return fetch({
        url: `/contact/${id}/linkedinChat/?accountId=${accountId}&salesInbox=${salesInbox}`,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}
ContactService.contactSendLinkedinChat = function (id, accountId, data, salesInbox = false) {
    return fetch({
        url: `/contact/${id}/linkedinChat/?accountId=${accountId}&salesInbox=${salesInbox}`,
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data
    })
}

ContactService.contactAddNote = function (id, contactId, data) {
    return fetch({
        url: `/contact/${id}/notes?contactId=${contactId}`,
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data
    })
}
ContactService.contactDeleteNote = function (id, contactId, data) {
    let queryString = bodyToQueryString(data);
    return fetch({
        url: `/contact/${id}/notes/?${queryString}&contactId=${contactId}`,
        method: 'delete',
        headers: {
            'public-request': "true"
        }
    })
}
ContactService.contactAddTag = function (id, contactId, data) {
    return fetch({
        url: `/contact/${id}/tags` + `?contactId=${contactId}`,
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data
    })
}
ContactService.contactDeleteTag = function (id, contactId, data) {
    return fetch({
        url: `/contact/${id}/tags/?tag=${data.tag}&contactId=${contactId}`,
        method: 'delete',
        headers: {
            'public-request': "true"
        }
    })
}
ContactService.contactDelete = function (data) {

    return fetch({
        url: '/contact/' + data,
        method: 'delete',
        headers: {
            'public-request': "true"
        },
        data
    })
}
ContactService.contactUpdate = function (id, contactId, data) {

    return fetch({
        url: '/contact/' + id + `?contactId=${contactId}`,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}

function cleanlinkedinCampaignData(data) {
    const copy = { ...data, ...{} };
    delete copy.testResult;
    delete copy.csvResult;
    delete copy.nameError;
    delete copy.added;
    delete copy.accountId;
    return copy;
}


export default ContactService;