import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { crawlerSuccess, quickSearchError, quickSearchSuccess, crawlerError, crawlerGetLeadsError, crawlerGetLeadsSuccess } from "redux/actions";
import {
    QUICK_SEARCH_TEST, QUICK_SEARCH_ADD, QUICK_SEARCH_LIST, QUICK_SEARCH_DELETE, QUICK_SEARCH_UPDATE,
    CRAWLER_LIST, CRAWLER_GET, CRAWLER_UPDATE, CRAWLER_DELETE, CRAWLER_DUPLICATE,
} from "redux/constants/Crawler";
import { CRAWLER_LEAD_LIST } from 'redux/constants/SearchLead';
import Service from 'services/CrawlerService';
export function* testSearch() {
    yield takeLatest(QUICK_SEARCH_TEST, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.testSearch, params.data);
            yield put(quickSearchSuccess({ result, success: true, isShowMessage: false, apiCall: QUICK_SEARCH_TEST }));
        } catch (err) {
            yield put(quickSearchError({ isShowMessage: true, success: false, message: err.message, apiCall: QUICK_SEARCH_TEST }))
        }
    })

}

export function* quickSearchAdd() {
    yield takeLatest(QUICK_SEARCH_ADD, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.quickSearchAdd, params.data);
            yield put(quickSearchSuccess({ result, success: true, isShowMessage: false, apiCall: QUICK_SEARCH_ADD }));
        } catch (err) {
            yield put(quickSearchError({ isShowMessage: true, success: false, message: err.message, apiCall: QUICK_SEARCH_ADD }))
        }
    })

}

export function* quickSearchList() {
    yield takeLatest(QUICK_SEARCH_LIST, function* (params) {
        try {
            const result = yield call(Service.quickSearchList, params.accountId);
            yield put(quickSearchSuccess({ result, success: true, isShowMessage: false, apiCall: QUICK_SEARCH_LIST }));
        } catch (err) {
            yield put(quickSearchError({ isShowMessage: true, success: false, message: err.message, apiCall: QUICK_SEARCH_LIST }))
        }
    })

}

export function* quickSearchDelete() {
    yield takeLatest(QUICK_SEARCH_DELETE, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.quickSearchDelete, params.id);
            yield put(quickSearchSuccess({ result, success: true, isShowMessage: false, apiCall: QUICK_SEARCH_DELETE }));
        } catch (err) {
            yield put(quickSearchError({ isShowMessage: true, success: false, message: err.message, apiCall: QUICK_SEARCH_DELETE }))
        }
    })

}
export function* quickSearchUpdate() {
    yield takeLatest(QUICK_SEARCH_UPDATE, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.quickSearchUpdate, params.id, params.data);
            yield put(quickSearchSuccess({ result, success: true, isShowMessage: false, apiCall: QUICK_SEARCH_UPDATE }));
        } catch (err) {
            yield put(quickSearchError({ isShowMessage: true, success: false, message: err.message, apiCall: QUICK_SEARCH_UPDATE }))
        }
    })

}

export function* crawlerGet() {
    yield takeLatest(CRAWLER_GET, function* (params) {
        try {

            const result = yield call(Service.crawlerGet, params.data);
            yield put(crawlerSuccess({ result, success: true, isShowMessage: false, apiCall: CRAWLER_GET }));
        } catch (err) {
            yield put(crawlerError({ isShowMessage: true, success: false, message: err.message, apiCall: CRAWLER_GET }))
        }
    })

}
export function* crawlerList() {
    yield takeLatest(CRAWLER_LIST, function* (params) {
        try {
            const result = yield call(Service.crawlerList, params.accountId);
            yield put(crawlerSuccess({ result, success: true, isShowMessage: false, apiCall: CRAWLER_LIST }));
        } catch (err) {
            yield put(crawlerError({ isShowMessage: true, success: false, message: err.message, apiCall: CRAWLER_LIST }))
        }
    })

}

export function* crawlerUpdate() {
    yield takeLatest(CRAWLER_UPDATE, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.crawlerUpdate, params.id, params.data);
            yield put(crawlerSuccess({ result, success: true, isShowMessage: false, apiCall: CRAWLER_UPDATE }));
        } catch (err) {
            yield put(crawlerError({ isShowMessage: true, success: false, message: err.message, apiCall: CRAWLER_UPDATE }))
        }
    })

}
export function* crawlerGetLeads() {
    yield takeLatest(CRAWLER_LEAD_LIST, function* (params) {
        try {
            const result = yield call(Service.getLeads, params.data);
            yield put(crawlerGetLeadsSuccess({ result, success: true, isShowMessage: false, apiCall: CRAWLER_LEAD_LIST }));
        } catch (err) {
            yield put(crawlerGetLeadsError({ isShowMessage: true, success: false, message: err.message, apiCall: CRAWLER_LEAD_LIST }))
        }
    })

}
export function* crawlerDelete() {
    yield takeLatest(CRAWLER_DELETE, function* (params) {
        try {
            const result = yield call(Service.delete, params.data);
            yield put(crawlerSuccess({ result, success: true, isShowMessage: false, apiCall: CRAWLER_DELETE }));
        } catch (err) {
            yield put(crawlerError({ isShowMessage: true, success: false, message: err.message, apiCall: CRAWLER_DELETE }))
        }
    })

}
export function* crawlerDuplicate() {
    yield takeLatest(CRAWLER_DUPLICATE, function* (params) {
        try {
            const result = yield call(Service.duplicate, params.id);
            yield put(crawlerSuccess({ result, success: true, isShowMessage: false, apiCall: CRAWLER_DUPLICATE }));
        } catch (err) {
            yield put(crawlerError({ isShowMessage: true, success: false, message: err.message, apiCall: CRAWLER_DUPLICATE }))
        }
    })

}
export default function* rootSaga() {
    yield all([
        fork(testSearch),
        fork(quickSearchAdd),
        fork(quickSearchUpdate),
        fork(quickSearchDelete),
        fork(quickSearchList),
        fork(crawlerList),
        fork(crawlerGet),
        fork(crawlerUpdate),
        fork(crawlerGetLeads),
        fork(crawlerDuplicate),
        fork(crawlerDelete),
    ]);
}