import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { MESSAGE_TEMPLATE_ERROR, MESSAGE_TEMPLATE_DELETE, MESSAGE_TEMPLATE_GET, MESSAGE_TEMPLATE_HIDE_MESSAGE, MESSAGE_TEMPLATE_LIST, MESSAGE_TEMPLATE_SUCCESS, MESSAGE_TEMPLATE_UPDATE, MESSAGE_TEMPLATE_NEW } from "redux/constants/MessageTemplate"
import Service from 'services/MessageTemplateService';
import { messageTemplateSuccess, messageTemplateError } from 'redux/actions/MessageTemplate';
export function* messageTemplateList() {
    yield takeLatest(MESSAGE_TEMPLATE_LIST, function* (params) {
        try {
            const result = yield call(Service.messageTemplateList, params.data);
            yield put(messageTemplateSuccess({ result, success: true, isShowMessage: false, apiCall: MESSAGE_TEMPLATE_LIST }));
        } catch (err) {
            yield put(messageTemplateError({ isShowMessage: true, success: false, message: err.message, apiCall: MESSAGE_TEMPLATE_LIST }))
        }
    })

}
export function* messageTemplateUpdate() {
    yield takeLatest(MESSAGE_TEMPLATE_UPDATE, function* (params) {
        try {
            const result = yield call(Service.messageTemplateUpdate, params.id, params.messageTemplateId, params.data);
            result.id = params.id;
            yield put(messageTemplateSuccess({ result, success: true, isShowMessage: false, apiCall: MESSAGE_TEMPLATE_UPDATE }));
        } catch (err) {
            yield put(messageTemplateError({ isShowMessage: true, success: false, message: err.message, apiCall: MESSAGE_TEMPLATE_UPDATE }))
        }
    })

}

export function* MessageTemplateNew() {
    yield takeLatest(MESSAGE_TEMPLATE_NEW, function* (params) {
        try {
            const result = yield call(Service.MessageTemplateNew, params.data);
            result.id = params.id;
            yield put(messageTemplateSuccess({ result, success: true, isShowMessage: false, apiCall: MESSAGE_TEMPLATE_NEW }));
        } catch (err) {
            yield put(messageTemplateError({ isShowMessage: true, success: false, message: err.message, apiCall: MESSAGE_TEMPLATE_NEW }))
        }
    })

}
export function* messageTemplateGet() {
    yield takeLatest(MESSAGE_TEMPLATE_GET, function* (params) {
        try {

            const result = yield call(Service.messageTemplateGet, params.data);
            yield put(messageTemplateSuccess({ result, success: true, isShowMessage: false, apiCall: MESSAGE_TEMPLATE_GET }));
        } catch (err) {
            yield put(messageTemplateError({ isShowMessage: true, success: false, message: err.message, apiCall: MESSAGE_TEMPLATE_GET }))
        }
    })

}

export default function* rootSaga() {
    yield all([
        fork(messageTemplateList)
    ]);
}