import fetch from 'auth/FetchInterceptor'

const SubscriptionStripeService = {};

// /stripe.Setup({ vendor: 2556 });
//stripe.Setup({ vendor: 132733 });
const stripe = window.stripe;
SubscriptionStripeService.plans = function () {

    return fetch({
        url: '/stripe/plans',
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}
SubscriptionStripeService.cancel = function (accountId) {
    return fetch({
        url: '/stripe/subscription',
        method: 'delete',
        headers: {
            'public-request': 'true'
        },
        data: { accountId }
    })
}
SubscriptionStripeService.switchPlan = function (inAccountId, newPlanId) {
    return fetch({
        url: '/stripe/' + inAccountId + "/subscription",
        method: 'put',
        headers: {
            'public-request': 'true'
        },
        data: { newPlanId }
    })
}
SubscriptionStripeService.resume = function (accountId) {
    return fetch({
        url: '/stripe/subscription/resume',
        method: 'put',
        headers: {
            'public-request': 'true'
        },
        data: { accountId }
    })
}
SubscriptionStripeService.subscribeToPlan = function (data) {
    return fetch({
        url: '/stripe/subscription',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data
    })
}

export default SubscriptionStripeService;