import fetch from 'auth/FetchInterceptor'

const DataService = {};

// /Paddle.Setup({ vendor: 2556 });
//Paddle.Setup({ vendor: 132733 });
const Paddle = window.Paddle;
DataService.plans = function () {

    return fetch({
        url: '/paddle/plans',
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}
DataService.cancel = function (inAccountId) {
    return fetch({
        url: '/paddle/' + inAccountId + "/subscriptions",
        method: 'delete',
        headers: {
            'public-request': 'true'
        },

    })
}
DataService.switchPlan = function (inAccountId, newPlanId) {
    return fetch({
        url: '/paddle/' + inAccountId + "/subscriptions",
        method: 'put',
        headers: {
            'public-request': 'true'
        },
        data: { newPlanId }
    })
}
DataService.subscribeToPlan = function (planId, email, id) {
    return new Promise((resolve, reject) => {
        Paddle.Checkout.open({
            product: planId,
            email: email,
            passthrough: id,
            successCallback: (data) => {
                console.log(data);
                resolve(data);
            },
            closeCallback: (data) => {
                console.log(data);
                reject(data);
            }
        });
    })
}

export default DataService;