import fetch from 'auth/FetchInterceptor'
const DataService = {};
DataService.getCampaignOptions = function (data) {
    return fetch({
        url: '/system/campaignOptions',
        method: 'get',
        headers: {
            'public-request': "true"
        },
        data
    })
}
DataService.getContactOptions = function (data) {
    return fetch({
        url: '/system/contactOptions',
        method: 'get',
        headers: {
            'public-request': "true"
        },
        data
    })
}

DataService.getAccountOptions = function (data) {
    return fetch({
        url: '/system/accountOptions',
        method: 'get',
        headers: {
            'public-request': "true"
        },
        data
    })
}


export default DataService;