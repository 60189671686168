import fetch from 'auth/FetchInterceptor'

const InAccountService = {};


function bodyToQueryString(body) {
    return Object.keys(body).filter(
        key => body[key] !== undefined
    ).map(
        key => {
            if (body[key] !== undefined)
                return key + '=' + body[key]
            else
                return "";
        }
    ).join("&");
}


InAccountService.list = function (options) {
    const { pagination, query } = options;
    delete options.pagination;
    delete options.query;
    const { current: page, pageSize: limit, sortBy, sortType } = pagination;

    options = { ...options, ...query, page, limit, sortType, sortBy };
    var queryString = bodyToQueryString(options);
    return fetch({
        url: '/accounts/summaryList?' + queryString,
        method: 'get',
        headers: {
            'public-request': "true"
        }
    })
}
InAccountService.add = function (data) {
    return fetch({
        url: '/accounts',
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data
    })
}
InAccountService.delete = function (id) {
    return fetch({
        url: '/accounts/' + id,
        method: 'delete',
        headers: {
            'public-request': 'true'
        },

    })
}
InAccountService.data = function (id) {
    return fetch({
        url: '/accounts/' + id,
        method: 'get',
        headers: {
            'public-request': 'true'
        },

    })
}
InAccountService.update = function (id, data) {
    if (data.status != undefined) {
        let url = data.status == "active" ? `/accounts/${id}/unpause` : `/accounts/${id}/pause`
        return fetch({
            url,
            method: 'put', headers: { 'public-request': 'true' },
            data: { pauser: "human" }
        })
    }
    else return fetch({
        url: '/accounts/' + id,
        method: 'put',
        headers: {
            'public-request': 'true'
        },
        data
    })
}
InAccountService.updateField = function (id, data) {
    return fetch({
        url: '/accounts/' + id,
        method: 'put',
        headers: {
            'public-request': 'true'
        },
        data
    })
}
InAccountService.updatePassword = function (id, data) {
    return fetch({
        url: '/accounts/' + id + "/updatePassword",
        method: 'put',
        headers: {
            'public-request': 'true'
        },
        data
    })
}
InAccountService.deactivate = function (id, data) {
    return fetch({
        url: '/accounts/' + id + "/deactivate",
        method: 'put',
        headers: {
            'public-request': 'true'
        },
        data
    })
}

InAccountService.verify = function (data) {
    return fetch({
        url: '/accounts/' + data.id + "/verify",
        method: 'post',
        headers: {
            'public-request': 'true'
        },
        data: { code: data.code }

    })
}
InAccountService.resendCode = function (id) {
    return fetch({
        url: '/accounts/' + id + "/resendCode",
        method: 'get',
        headers: {
            'public-request': 'true'
        },

    })
}

InAccountService.getActivities = function (id, options = {}) {
    var queryString = bodyToQueryString(options);

    return fetch({
        url: `/accounts/${id}/activities/?${queryString}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },
    })
}
InAccountService.updateNotificationSettings = function (id, data) {
    return fetch({
        url: `/accounts/${id}/notificationSettings/`,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}
InAccountService.testWebhookUrl = function (id, data) {
    return fetch({
        url: `/accounts/${id}/testWebhookUrl/`,
        method: 'post',
        headers: {
            'public-request': "true"
        },
        data
    })
}

InAccountService.updateBotSettings = function (id, data) {
    return fetch({
        url: `/accounts/${id}/botSettings/`,
        method: 'put',
        headers: {
            'public-request': "true"
        },
        data
    })
}

InAccountService.getImportStatus = function (id, type) {
    return fetch({
        url: `/accounts/${id}/importStatus/?type=${type}`,
        method: 'get',
        headers: {
            'public-request': "true"
        },

    })
}

InAccountService.sendInvite = function (id) {
    return fetch({
        url: `/accounts/${id}/testInvite/`,
        method: 'put',
        headers: {
            'public-request': "true"
        },

    })
}

InAccountService.testSalesNavigator = function (id) {
    return fetch({
        url: `/accounts/${id}/testSalesNavigator/`,
        method: 'put',
        headers: {
            'public-request': "true"
        },

    })
}
export default InAccountService;