import { all } from 'redux-saga/effects';
import Auth from './Auth';
import User from './User';
import LiAccount from './LiAccount';
import Subscription from './Subscription';
import Crawler from './Crawler';
import LinkedinCampaign from './LinkedinCampaign';
import Campaign from './Campaign';
import System from './System';
import Contact from './Contact';
import MessageTemplate from './MessageTemplate';
import Inbox from './Inbox';
import NewCrawler from './NewCrawler';
import StripeSubscription from './SubscriptionStripe';
import PostAlerts from './PostAlerts';
import Billings from './Billings';
export default function* rootSaga(getState) {
  yield all([Inbox(), NewCrawler(), PostAlerts(), Billings(),
  Auth(), User(), Contact(), LiAccount(), Subscription(), Crawler(), LinkedinCampaign(), Campaign(), System(), MessageTemplate(), StripeSubscription()
  ]);
}
