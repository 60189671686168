export const QUICK_SEARCH_SET_STEP = "QUICK_SEARCH_SET_STEP";
export const QUICK_SEARCH_SET_WIZARD_STATE = "QUICK_SEARCH_SET_WIZARD_STATE";
export const QUICK_SEARCH_SET_WIZARD_EMAIL = "QUICK_SEARCH_SET_WIZARD_EMAIL";
export const QUICK_SEARCH_SET_WIZARD = "QUICK_SEARCH_SET_WIZARD";
export const QUICK_SEARCH_TEST = "QUICK_SEARCH_TEST";
export const QUICK_SEARCH_SUCCESS = "QUICK_SEARCH_SUCCESS";
export const QUICK_SEARCH_ERROR = "QUICK_SEARCH_ERROR";
export const QUICK_SEARCH_HIDE_MESSAGE = "QUICK_SEARCH_HIDE_MESSAGE";
export const QUICK_SEARCH_ADD = "QUICK_SEARCH_ADD";
export const QUICK_SEARCH_INIT = "QUICK_SEARCH_INIT";
export const QUICK_SEARCH_UPDATE = "QUICK_SEARCH_UPDATE";
export const QUICK_SEARCH_LIST = "QUICK_SEARCH_LIST";
export const QUICK_SEARCH_DELETE = "QUICK_SEARCH_DELETE";
export const CRAWLER_LIST = "CRAWLER_LIST";
export const CRAWLER_GET = "CRAWLER_GET";
export const CRAWLER_ERROR = "CRAWLER_ERROR";
export const CRAWLER_SUCCESS = "CRAWLER_SUCCESS";
export const CRAWLER_HIDE_MESSAGE = "CRAWLER_HIDE_MESSAGE";
export const CRAWLER_SET_STATE = "CRAWLER_SET_STATE";
export const CRAWLER_UPDATE = "CRAWLER_UPDATE";
export const CRAWLER_DELETE = "CRAWLER_DELETE";
export const CRAWLER_DUPLICATE = "CRAWLER_DUPLICATE";

export const NEW_CRAWLER_SET_STEP = "NEW_CRAWLER_SET_STEP";
export const NEW_CRAWLER_SET_WIZARD_STATE = "NEW_CRAWLER_SET_WIZARD_STATE";
export const NEW_CRAWLER_SET_WIZARD = "NEW_CRAWLER_SET_WIZARD";
export const NEW_CRAWLER = "NEW_CRAWLER";
export const NEW_CRAWLER_ADD = "NEW_CRAWLER_ADD";
export const NEW_CRAWLER_INIT = "NEW_CRAWLER_INIT";
export const NEW_CRAWLER_RESET = "NEW_CRAWLER_RESET";
export const NEW_CRAWLER_SUCCESS = "NEW_CRAWLER_SUCCESS";
export const NEW_CRAWLER_ERROR = "NEW_CRAWLER_ERROR";
export const NEW_CRAWLER_HIDE_MESSAGE = "NEW_CRAWLER_HIDE_MESSAGE";
export const NEW_CRAWLER_SET_SOURCE = "NEW_CRAWLER_SET_SOURCE";
export const NEW_CRAWLER_SOURCE_TEST = "NEW_CRAWLER_SOURCE_TEST";

export const SYSTEM_SEARCH_RESULT_FILTERS = ["premium", "emailRequired", "profilePicture", "birthDateOn", "student"];
