import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { newCrawlerError, newCrawlerSuccess } from 'redux/actions/NewCrawler';
import { NEW_CRAWLER_ADD, NEW_CRAWLER_SOURCE_TEST } from 'redux/constants/Crawler';

import Service from 'services/CrawlerService';
export function* newCrawlerTest() {
    yield takeLatest(NEW_CRAWLER_SOURCE_TEST, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.testSearch, params.data);
            yield put(newCrawlerSuccess({ result, success: true, isShowMessage: false, apiCall: NEW_CRAWLER_SOURCE_TEST }));
        } catch (err) {
            yield put(newCrawlerError({ isShowMessage: true, success: false, message: err.message, apiCall: NEW_CRAWLER_SOURCE_TEST }))
        }
    })

}


export function* newCrawlerAdd() {
    yield takeLatest(NEW_CRAWLER_ADD, function* (params) {
        try {
            params.data.advance = true;
            const result = yield call(Service.add, params.data);
            yield put(newCrawlerSuccess({ result, success: true, isShowMessage: false, apiCall: NEW_CRAWLER_ADD }));
        } catch (err) {
            yield put(newCrawlerError({ isShowMessage: true, success: false, message: err.message, apiCall: NEW_CRAWLER_ADD }))
        }
    })

}


export default function* rootSaga() {
    yield all([
        fork(newCrawlerTest),
        fork(newCrawlerAdd),

    ]);
}