import React, { } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  EditOutlined,
  IdcardOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import { useSelector } from "react-redux";
import user from "redux/reducers/User";
const menuItem = [


  {
    title: "Account Setting",
    icon: IdcardOutlined,
    path: "/"
  },

]

export const NavProfile = ({ signOut }) => {
  const userData = useSelector((state) => state.user.userData);
  if (userData) {
    var profileImg = userData.avatar || "/img/avatars/avatar.png";
    var profileMenu = (
      <div className="nav-profile nav-dropdown">
        <div className="nav-profile-header">
          <div className="d-flex">
            <Avatar size={40} src={profileImg} />
            <div className="pl-3">
              <h4 className="mb-0">{userData.name}</h4>
              <span className="text-muted">{userData.email}</span>
            </div>
          </div>
        </div>
        <div className="nav-profile-body">
          <Menu>
            {menuItem.map((el, i) => {
              return (
                <Menu.Item key={i}>
                  <Link to={"/app/accounts/" + userData.id}>
                    <Icon className="mr-3" type={el.icon} />
                    <span className="font-weight-normal">{el.title}</span>
                  </Link>
                </Menu.Item>
              );
            })}
            <Menu.Item key={menuItem.length + 1} onClick={e => signOut()}>
              <span>
                <LogoutOutlined className="mr-3" />
                <span className="font-weight-normal">Sign Out</span>
              </span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
    );
  }
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
}

export default connect(null, { signOut })(NavProfile)
