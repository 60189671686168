import {
    GET_SYSTEM_OPTIONS, GET_SYSTEM_OPTIONS_ERROR, GET_SYSTEM_OPTIONS_SUCCESS, GET_SYSTEM_CAMPAIGN_OPTIONS, GET_ACCOUNT_OPTIONS, GET_SYSTEM_CONTACT_OPTIONS
} from "redux/constants/System";

export function systemOptionsGet(data) {
    return {
        type: GET_SYSTEM_OPTIONS,
        data
    }
}
export function accountOptionsGet(data) {
    return {
        type: GET_ACCOUNT_OPTIONS,
        data
    }
}
export function systemOptionsSuccess(data) {
    return {
        type: GET_SYSTEM_OPTIONS_SUCCESS,
        data,
    }
}
export function systemOptionsError(data) {
    return {
        type: GET_SYSTEM_OPTIONS_ERROR,
        data,
    }
}
export function systemCampaignOptionsGet(data) {
    return {
        type: GET_SYSTEM_CAMPAIGN_OPTIONS,
        data,
    }
}

export function systemContactOptionsGet(data) {
    return {
        type: GET_SYSTEM_CONTACT_OPTIONS,
        data,
    }
}