import { INBOX_ERROR, INBOX_GET, INBOX_HIDE_MESSAGE, INBOX_RESET, INBOX_SALES_GET, INBOX_SET_QUERY, INBOX_SET_QUERY_SALES, INBOX_SET_TYPE, INBOX_SUCCESS } from "redux/constants/Inbox";

export function inboxGet(data) {
    return {
        type: INBOX_GET,
        data
    }
}
export function inboxSalesGet(data) {
    return {
        type: INBOX_SALES_GET,
        data
    }
}

export function inboxSuccess(data) {
    return {
        type: INBOX_SUCCESS,
        data,
    }
}
export function inboxHideMessage() {
    return {
        type: INBOX_HIDE_MESSAGE,
    }
}
export function inboxError(data) {
    return {
        type: INBOX_ERROR,
        data

    }
}
export function inboxSetType(data) {
    return {
        type: INBOX_SET_TYPE,
        data

    }
}
export function inboxSetQuery(data) {
    return {
        type: INBOX_SET_QUERY,
        data

    }
}
export function inboxSetQuerySales(data) {
    return {
        type: INBOX_SET_QUERY_SALES,
        data

    }
}

export function inboxReset() {
    return {
        type: INBOX_RESET,


    }
}