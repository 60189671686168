import { all, put, fork, call, takeLatest } from 'redux-saga/effects';
import { MESSAGE_TEMPLATE_ERROR, MESSAGE_TEMPLATE_DELETE, MESSAGE_TEMPLATE_GET, MESSAGE_TEMPLATE_HIDE_MESSAGE, MESSAGE_TEMPLATE_LIST, MESSAGE_TEMPLATE_SUCCESS, MESSAGE_TEMPLATE_UPDATE, MESSAGE_TEMPLATE_NEW } from "redux/constants/MessageTemplate"
import Service from 'services/InboxService';
import { inboxError, inboxSuccess } from 'redux/actions/Inbox';
import { INBOX_GET, INBOX_SALES_GET } from 'redux/constants/Inbox';

export function* InboxGet() {
    yield takeLatest(INBOX_GET, function* (params) {
        try {

            const result = yield call(Service.getInbox, params.data);
            yield put(inboxSuccess({ result, success: true, isShowMessage: false, apiCall: INBOX_GET }));
        } catch (err) {
            yield put(inboxError({ isShowMessage: true, success: false, message: err.message, apiCall: INBOX_GET }))
        }
    })

}
export function* InboxSalesGet() {
    yield takeLatest(INBOX_SALES_GET, function* (params) {
        try {

            const result = yield call(Service.getInbox, params.data);
            yield put(inboxSuccess({ result, success: true, isShowMessage: false, apiCall: INBOX_SALES_GET }));
        } catch (err) {
            yield put(inboxError({ isShowMessage: true, success: false, message: err.message, apiCall: INBOX_SALES_GET }))
        }
    })

}
export default function* rootSaga() {
    yield all([
        fork(InboxGet),
        fork(InboxSalesGet),
    ]);
}